import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Close from 'components/utils/icons/Close';
import { CloseWrapper } from '../styles';
import { Gradient } from './styles';

class SignUpSuccess extends Component {
  constructor(props) {
    super(props);
    this.dismissTimer = null;
    this.dismissBanner = this.dismissBanner.bind(this);
  }

  componentDidMount() {
    this.dismissTimer = setTimeout(() => {
      this.props.dismiss();
    }, 3000);
  }

  dismissBanner() {
    clearTimeout(this.dismissTimer);
    this.props.dismiss();
  }

  render() {
    const { dismissable } = this.props;
    return (
      <Gradient>
        <h2>Knowledge + Savings → Your inbox 🤓💰</h2>
        {dismissable
          && <CloseWrapper onClick={() => this.dismissBanner()}>
            <Close />
          </CloseWrapper>
        }
      </Gradient>
    );
  }
}

SignUpSuccess.propTypes = {
  dismissable: PropTypes.bool,
  dismiss: PropTypes.func,
};

export default SignUpSuccess;
