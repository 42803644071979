/* globals process */
import { reportError } from 'helpers/sentryHelper';

class APIGateway {
  constructor() {
    this.apiReleaseStage = 'staging';
    this.apiUrl = 'https://txttc75bdd.execute-api.us-west-1.amazonaws.com';
    if (process.env.GATSBY_RELEASE_ENV === 'production') {
      this.apiReleaseStage = 'v1';
    } else if (process.env.GATSBY_RELEASE_ENV === 'development') {
      this.apiReleaseStage = 'dev';
    }
  }

  request(endPoint, method, data, cb) {
    const url = `${this.apiUrl}/${this.apiReleaseStage}/${endPoint}`;
    // Default GET method set if none is provided
    this.method = method ? method : 'GET';
    this.data = data ? data : {};
    this.response = {};
    fetch(url, {
      method: this.method,
      body: JSON.stringify(this.data),
    })
      .then((res) => {
        if (!res || res.status !== 200) {
          throw new Error(JSON.stringify(res));
        }
        this.response.status = res.status;
        return res.json();
      })
      .then((data) => {
        if (data.error) {
          this.response.error = data.error;
        } else {
          this.response.data = data;
        }
        cb(this.response);
      })
      .catch((err) => {
        /* eslint-disable-next-line no-console */
        console.log('caught error ', err);
        this.response.error = err;
        reportError(`Error submitting ${this.method} request to API Gateway`, this.data);
        cb(this.response);
      });
  }
}

export default APIGateway;
