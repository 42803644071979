import styled from 'styled-components';
import { variables, media } from 'styleUtils';
import Section from 'styledComponents/Section';

const BannerSection = styled(Section)`
  background-color: ${variables.darkGrey};
  padding: 1rem 0 !important;
`;

const Wrapper = styled.div`
  width: 90%;
  text-align: center;
  margin: 0 auto;

  p {
    max-width: 325px;
    color: ${variables.white};
    margin: 0 auto;
    font-size: 0.875rem;
    font-family: 'GT-pressura';

    span {
      border-bottom: solid 1px ${variables.white};
    }

    ${media.tablet`
      max-width: 100%;
    `}
  }
`;

const Spacer = styled.div`
  height: 60px;
`;

export { Wrapper, BannerSection, Spacer };
