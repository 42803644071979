import React from 'react';

const Instagram = () => (
    <svg width="38px" height="38px" viewBox="0 0 38 38" version="1.1" xmlns="http://www.w3.org/2000/svg">
    <title>Instagram</title>
        <g id="Page-2" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
            <g id="instagram-white" fillRule="nonzero">
                <path d="M26.7500092,4.58213076e-06 L10.9100092,4.58213076e-06 C8.01568369,-0.00264738463 5.23913872,1.14594263 3.19254067,3.19254067 C1.14594263,5.23913872 -0.00264738463,8.01568369 4.58213076e-06,10.9100092 L4.58213076e-06,26.7500092 C-0.00264738463,29.6443347 1.14594263,32.4208796 3.19254067,34.4674777 C5.23913872,36.5140757 8.01568369,37.6626657 10.9100092,37.6600138 L26.7500092,37.6600138 C29.6443347,37.6626657 32.4208796,36.5140757 34.4674777,34.4674777 C36.5140757,32.4208796 37.6626657,29.6443347 37.6600138,26.7500092 L37.6600138,10.9100092 C37.6626657,8.01568369 36.5140757,5.23913872 34.4674777,3.19254067 C32.4208796,1.14594263 29.6443347,-0.00264738463 26.7500092,4.58213076e-06 Z M34.0400092,26.6900092 C34.0400092,28.6393504 33.2656364,30.5088516 31.887244,31.887244 C30.5088516,33.2656364 28.6393504,34.0400092 26.6900092,34.0400092 L10.9700092,34.0400092 C6.91071627,34.0400092 3.62000918,30.7493021 3.62000918,26.6900092 L3.62000918,10.9700092 C3.62000918,6.91071627 6.91071627,3.62000918 10.9700092,3.62000918 L26.6900092,3.62000918 C28.6393504,3.62000918 30.5088516,4.39438196 31.887244,5.77277434 C33.2656364,7.15116672 34.0400092,9.02066798 34.0400092,10.9700092 L34.0400092,26.6900092 Z" id="Shape"></path>
                <path d="M18.8300092,9.04000918 C13.4231415,9.04000918 9.04000918,13.4231415 9.04000918,18.8300092 C9.04000918,24.2368769 13.4231415,28.6200092 18.8300092,28.6200092 C24.2368769,28.6200092 28.6200092,24.2368769 28.6200092,18.8300092 C28.6200092,13.4231415 24.2368769,9.04000918 18.8300092,9.04000918 L18.8300092,9.04000918 Z M18.8300092,25.0400092 C15.4344685,25.0400092 12.6793469,22.2920692 12.6702116,18.8965407 C12.6611217,15.5010123 15.4014551,12.738436 18.7969462,12.7200073 C22.1924374,12.701762 24.962448,15.4345804 24.9900092,18.8300092 C24.9900092,20.4637428 24.341011,22.0305629 23.185787,23.185787 C22.0305629,24.341011 20.4637428,24.9900092 18.8300092,24.9900092 L18.8300092,25.0400092 Z" id="Shape"></path>
                <circle id="Oval" cx="28.8000092" cy="8.96000918" r="2.44"></circle>
            </g>
        </g>
    </svg>
);

export default Instagram;