import React from 'react';
import PropTypes from 'prop-types';
import { Wrapper, BannerSection } from './styles';

const Promo99 = ({ promoCode }) => (
  <BannerSection>
    <Wrapper>
      <p>💸 GET YOUR FIRST SUBSCRIPTION SHIPMENT FOR $99 WITH CODE <span>{promoCode}</span>. 💸 </p>
    </Wrapper>
  </BannerSection>
);

export default Promo99;
Promo99.propTypes = {
  promoCode: PropTypes.string.isRequired,
};

