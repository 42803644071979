import React from 'react';
import ContextConsumer from 'components/Context';
import { BannerWrapper, Banner } from './styles';
import { object } from 'prop-types';

function shouldShowBanner(pathname) {
  const now = new Date();
  const currentTime = Math.round(now.getTime() / 1000);
  const start = 1622160000; // 2021-05-27T00:17:00 PST / Thursday May 28th, 5:00pm PST
  const end = 1622764800; // 2021-06-03T00:17:00 PST / Thursday June 3rd, 5:00pm PST
  if (pathname && pathname.includes('contact') && currentTime >= start && currentTime <= end) {
    return true;
  }
  return false;
}

const MemorialDay = ({ location, context }) => {
  const { pathname } = location;
  const { menuOpen } = context.data;
  const showBanner = shouldShowBanner(pathname);
  if (showBanner) {
    return (
      <BannerWrapper $menuOpen={menuOpen}>
        <Banner>
          <p>In honor of Memorial Day and Mental Health Awareness Month, the Apostrophe team will be slow to respond from 5/28-6/2. We are humans and need to take care of ourselves too - join us!</p>
        </Banner>
      </BannerWrapper>
    );
  }
  return null;
};

const Consumer = props => (
  <ContextConsumer>
    {context => <MemorialDay {...props} context={context} />}
  </ContextConsumer>
);

export default Consumer;

MemorialDay.propTypes = {
  location: object,
  context: object,
};
