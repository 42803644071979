import React from 'react';
import { Row, SVGLink} from '../styles';
import FacebookIcon from 'icons/SocialMedia/Facebook/Footer';
import InstagramIcon from 'icons/SocialMedia/Instagram/Footer';
import TiktokIcon from 'icons/SocialMedia/Tiktok';
import TwitterIcon from 'icons/SocialMedia/Twitter/Footer';
const SocialMedia = ({ isDesktop }) => (
    <Row className={isDesktop ? "desktopSocialMedia" : ""} > 
        <SVGLink to="http://www.facebook.com/hi.apostrophe">
            <FacebookIcon />
        </SVGLink>

        <SVGLink to="http://www.instagram.com/hi_apostrophe">
            <InstagramIcon/>
        </SVGLink>

        <SVGLink to="https://www.tiktok.com/@hi_apostrophe">
            <TiktokIcon/>   
        </SVGLink>
  
        <SVGLink to="http://www.twitter.com/hi_apostrophe">
            <TwitterIcon/>
        </SVGLink>
    </Row>
)

export default SocialMedia
