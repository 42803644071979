/* eslint-disable func-names */
/* globals safari */
function conditionFromCategory(category) {
  if (!category || ['General', 'All'].includes(category)) {
    return undefined;
  }
  return category.toLowerCase().replace(/\s/g, '');
}

function mapSizesToProps({ width }) {
  const obj = { size: 'desktop' };
  if (width) {
    if (width < 768) {
      obj.size = 'mobile';
    } else if (width < 1280) {
      obj.size = 'tablet';
    }
  }
  return obj;
}

const checkIfSafari = () => {
  if (typeof window !== 'undefined') {
    const usingSafari = (/constructor/i).test(window.HTMLElement) || (function (p) { return p.toString() === '[object SafariRemoteNotification]'; }(!window.safari || (typeof safari !== 'undefined' && safari.pushNotification)));
    if (usingSafari) {
      return true;
    }
  }
  return false;
};

const formatStates = states => states.filter(word => word.length == 2).join(', ');

export {
  conditionFromCategory,
  mapSizesToProps,
  checkIfSafari,
  formatStates,
};
