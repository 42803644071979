import styled, { css } from 'styled-components';
import { media } from 'styleUtils';
import { animated } from 'react-spring';

const Frame = styled.div`
  position: relative;
  padding: 4px 0px 0px 0px;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow-x: hidden;
  vertical-align: middle;
  overflow: hidden !important;
  padding-left: 20px;
  ${media.tablet`
    padding-left: 40px
  `}
`;

const Title = styled.span`
  vertical-align: middle;
  font-size: 16px;
  background-color: white;
  letter-spacing: -0.44px;
  display: flex;
  flex-wrap: wrap;
  padding: 5px;
  cursor: pointer;
  line-height: 16px;
  width: 140px;
  ${props => props.isOpen && css`
    background-color: ${props.color};
    margin-bottom: 5px;
  `}
  ${media.tablet`
    :hover {
      color: #333333;
      transition: all 0.7s ease;
      ${props => `background-color: ${props.color}`};
    }
    font-size: 19px;
    line-height: 27px;
    letter-spacing: -0.6px;
    width: 235px;
    height: 27px;
    justify-content: space-between;
    align-items: flex-start;
    padding-top: 0px;
  `}
  p {
    color: #333333;
    margin-right: 15px;
  }
`;

const Content = styled(animated.div)`
  will-change: transform, opacity, height;
  margin-left: 6px;
  padding: 0px 0px 0px 14px;
  border-left: 1px dashed rgba(255, 255, 255, 0.4);
  overflow: hidden;
  ${media.tablet`

  `}
  li {
    list-style-type: none;
    margin: 0;
    ${media.tablet`
      margin: 0;
    `}
  }
`;

const toggle = {
  width: '1em',
  height: '1em',
  marginRight: 10,
  cursor: 'pointer',
  verticalAlign: 'middle',
};

const CaretWrapper = styled.div`
  width: 10px;
  height: 10px;
  transform: rotate(180deg);
  margin-top: 8px;
  ${props => props.isOpen && css`
    transform: rotate(0deg);
    margin-top: 0px;
  `}
  ${media.tablet`
    margin-top: 20px;
    ${props => props.isOpen && css`
      margin-top: -2px;
    `}
  `}
  svg {
    width: 100%;
    height: 100%;
  }
`;

export {
  Frame,
  Title,
  toggle,
  CaretWrapper,
  Content,
};
