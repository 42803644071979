import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';

/* Use this with complex components (usually containing react-media queries)
  that need to force a re-render when the react component mounts.
  Pass a simplified version of the content for server rendering (including text and links),
  then the real component formats once its on the client.
*/
class ComplexRender extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isClient: false,
    };
  }

  componentDidMount() {
    this.setState({
      isClient: true,
    });
  }

  render() {
    const { clientRender, serverRender } = this.props;
    const { isClient } = this.state;
    return <Fragment>{isClient ? clientRender : serverRender}</Fragment>;
  }
}

export default ComplexRender;

ComplexRender.propTypes = {
  clientRender: PropTypes.object,
  serverRender: PropTypes.object,
};

