import React from 'react';
import PropTypes from 'prop-types';
import { Amplify } from 'aws-amplify';

import aws_config from 'src/aws_config';
import '../style/normalize.scss';
import '../style/base.scss';
import MainLayout from './components/Main';
import SEMLayout from './components/SEM';
import LPLayout from './components/LP';
import BlogLayout from './components/Blog';

import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import 'react-lazy-load-image-component/src/effects/blur.css';

Amplify.configure(aws_config);

const LayoutWrapper = ({ children, location, pageContext }) => {
  if (pageContext.layout == 'SEM') {
    return <SEMLayout location={location}> {children} </SEMLayout>;
  } else if (pageContext.layout == 'LP') {
    return <LPLayout location={location}>{children}</LPLayout>;
  } else if (pageContext.layout == 'blog') {
    return <BlogLayout location={location}>{children}</BlogLayout>;
  }
  return (
    <MainLayout location={location} noNewsletter={true}>
      {children}
    </MainLayout>
  );
};

LayoutWrapper.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.object,
    PropTypes.array,
  ]),
  location: PropTypes.object,
  pageContext: PropTypes.object,
};


export default LayoutWrapper;
