import React from 'react';
import ContextConsumer from 'components/Context';
import { BannerWrapper, Banner, Title, Disclaimer } from './styles';
import PropTypes from 'prop-types';

const BannerTemplate = ({ location, context }) => {
  const { pathname } = location;
  const { menuOpen } = context.data;
  const allowedPaths = ['/lp/aug23/'];
  const notAllowedPaths = [''];

  if (allowedPaths.includes(pathname) && !notAllowedPaths.includes(pathname)) {
    return (
      <BannerWrapper $menuOpen={menuOpen}>
        <Banner>
          <Title><span className="prefix">SALE EXTENDED!</span>Use code <span className="tag">NEW23</span> to get your mystery discount.</Title>
          <Disclaimer>Your surprise discount will be revealed when you apply the code at checkout. Discount can only be used on your first derm visit. Discount cannot be applied to previous visits. Discount ends 1/31/2024 at 11:59 PM PST.</Disclaimer>
        </Banner>
      </BannerWrapper>
    );
  }
  return null;
};

const Consumer = props => (
  <ContextConsumer>
    {context => <BannerTemplate {...props} context={context} />}
  </ContextConsumer>
);

export default Consumer;

BannerTemplate.propTypes = {
  location: PropTypes.object,
  context: PropTypes.object,
};
