import React, { Fragment } from 'react';
import Link from 'components/utils/Link';



const FooterMainLinks = () => (
  <Fragment>
    <div className="site-links">
      <div className="links links-primary">
        <div className="treatment-links">
          <Link to="/get/acne-treatment/">Acne</Link>
          <Link to="/get/anti-aging-treatment/">Wrinkles</Link>
          <Link to="/get/rosacea-treatment/">Rosacea</Link>
          <Link to="/get/latisse/">Eyelashes</Link>
        </div>
      </div>

      <div className="links">
        <div className="links-secondary">
          <Link to="/philosophe/">Philosophe</Link>
          <Link to="/products/">Products</Link>
          <Link to="/dermatologists/">Derms</Link>
          <Link to="/pharmacy/">Pharmacy</Link>
          <Link to="/slather/">Slather</Link>
        </div>

        <div className="links-secondary">
          <Link to="/reviews/">Reviews</Link>
          <Link to="https://faq.apostrophe.com/">FAQs</Link>
          <Link to="https://faq.apostrophe.com/article/152-accessibility">Accessibility</Link>
          <Link to="/contact/">Contact Us</Link>
          <Link to="/investors/">Investors</Link>
        </div>
      </div>
    </div>

  </Fragment>
);

export default FooterMainLinks;
