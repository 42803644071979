import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import Close from 'components/utils/icons/Close';
import { CloseWrapper } from '../styles';
import { Content, Form, SquareButton } from './styles';

class SignupForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      email: '',
      botField: '',
    };

    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  handleChange(e) {
    const { name, value } = e.target;
    this.setState({
      [name]: value,
    });
  }

  validateForm() {
    const { botField } = this.state;
    return botField == '';
  }

  handleSubmit(e) {
    e.preventDefault();

    const formData = {
      'email': this.state.email,
      'the_possessive': true,
    };

    this.props.submitForm(formData);
    this.setState({
      email: '',
    });
  }

  render() {
    const { email, botField } = this.state;
    const { content, dismissable, dismiss, errorMsg, busy } = this.props;
    return (
      <Fragment>
        <Content>
          <h2>{content.title}</h2>
          <p>{content.description}</p>
          {dismissable
            && <CloseWrapper onClick={() => dismiss()} >
              <Close />
            </CloseWrapper>
          }
        </Content>

        <Form onSubmit={this.handleSubmit}>
          <p className="errorMsg">{errorMsg}</p>
          <input type="hidden" name="botField" value={botField} onChange={this.handleChange} />
          <input
            placeholder="Enter email"
            name="email"
            type="email"
            value={email}
            onChange={this.handleChange}
          />
          <SquareButton type="submit" disabled={busy}>
            Join
          </SquareButton>
        </Form>
      </Fragment>
    );
  }
}

SignupForm.propTypes = {
  submitForm: PropTypes.func,
  dismissable: PropTypes.bool,
  dismiss: PropTypes.func,
  errorMsg: PropTypes.string,
  content: PropTypes.object,
  busy: PropTypes.bool,
};

export default SignupForm;
