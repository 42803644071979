import React, { Component } from 'react';
import PropTypes from 'prop-types';
import LayoutWrapper from '../Wrapper';
import Navigation from 'components/Navigation';
import Footer from 'components/Footer';
import PromoBanner from 'components/banners/Promo';
import Consumer from 'components/banners/AcneLaunch';

const promoPages = [
  'latisse-promo',
  'influenster',
];

class SEMLayout extends Component {
  constructor(props) {
    super(props);
    this.state = {
      promoBanner: '',
    };
  }

  componentDidMount() {
    const { pathname } = this.props.location;
    const pageTitle = pathname.split('/').filter(item => item).pop();
    if (promoPages.indexOf(pageTitle) !== -1) {
      this.setState({ promoBanner: pageTitle });
    }
  }

  render() {
    const { location, children } = this.props;
    let bannerText = '';
    if (children.length > 1 && children[1].props.pageContext) {
      bannerText = children[1].props.pageContext.bannerText;
    }
    const { promoBanner } = this.state;
    return (
      <LayoutWrapper>
        {promoBanner && <PromoBanner pageTitle={promoBanner} /> }
        {bannerText && <Consumer location={location} content={bannerText} />}
        <Navigation
          location={location}
          hideMenu={true}
          hideAccount={true}
        />
        {children}
        <Footer location={location} />
      </LayoutWrapper>
    );
  }
}

SEMLayout.propTypes = {
  location: PropTypes.object,
  children: PropTypes.oneOfType([
    PropTypes.object,
    PropTypes.array,
  ]),
};

export default SEMLayout;
