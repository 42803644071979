/* globals process */
import React from 'react';
import Cookies from 'universal-cookie';
import PropTypes from 'prop-types';
import { BannerWrapper } from './styles';

const cookies = new Cookies();

class CookieHandler extends React.Component {
  constructor(props) {
    super(props);
    this.checkIfCookie = this.checkIfCookie.bind(this);
    this.dismissBanner = this.dismissBanner.bind(this);
    this.toggleDisplay = this.toggleDisplay.bind(this);
    this.setTimedCookie = this.setTimedCookie.bind(this);
    this.state = {
      'rebrand': {
        set: this.dismissBanner,
        onMount: this.checkIfCookie,
        display: true,
      },
      'promo99': {
        onMount: this.setTimedCookie,
        display: true,
      },
    };
  }

  componentDidMount() {
    const { action } = this.props;
    const { onMount } = this.state[action];

    if (onMount) {
      onMount();
    }
  }

  checkIfCookie() {
    const { cookie } = this.props;
    const cookieFound = Boolean(cookies.get(cookie));
    if (cookieFound) {
      this.toggleDisplay();
    }
  }

  dismissBanner() {
    const { cookie } = this.props;
    cookies.set(cookie, true, { domain: process.env.GATSBY_DOMAIN, sameSite: 'none' });
    this.toggleDisplay();
  }

  toggleDisplay() {
    const { action } = this.props;
    this.setState(state => ({
      [action]: {
        ...state[action],
        display: !state[action].display,
      },
    }));
  }

  setTimedCookie() {
    const { cookie } = this.props;
    const timeNow = new Date().toJSON();
    cookies.set(cookie, timeNow, { domain: process.env.GATSBY_DOMAIN, path: '/' });
  }

  render() {
    const { menuOpen, action } = this.props;
    return (
      <BannerWrapper menuOpen={menuOpen}>
        {this.props.render({
          ...this.state[action],
        })}
      </BannerWrapper>
    );
  }
}

CookieHandler.propTypes = {
  menuOpen: PropTypes.bool,
  action: PropTypes.string.isRequired,
  cookie: PropTypes.string.isRequired,
  render: PropTypes.func.isRequired,
};

export default CookieHandler;
