const links = {
  treatments: [
    {
      condition: 'eyelashes',
      link: '/get/acne-treatment/',
      text: 'Acne',
    },
    {
      condition: 'hairloss',
      link: '/get/tretinoin/',
      text: 'Wrinkles',
    },
    {
      condition: 'skin',
      link: '/get/rosacea-treatment/',
      text: 'Rosacea',
    },
    {
      condition: 'eyelashes',
      link: '/get/latisse/',
      text: 'Eyelashes',
    },
  ],
  products: [
    {
      condition: 'hairloss',
      link: '/products/sunscreen/',
      text: 'Sunscreen',
    },
    {
      condition: 'eyelashes',
      link: '/products/#topicals',
      text: 'Topicals',
    },
    {
      condition: 'hairloss',
      link: '/products/#orals',
      text: 'Orals',
    },
    {
      condition: 'wrinkles',
      link: '/products/body-acne',
      text: 'Body',
    },
  ],
  largeLinks: [
    {
      condition: 'skin',
      link: '/philosophe/',
      text: 'Philosophe',
    },
    {
      condition: 'skin',
      link: '/dermatologists/',
      text: 'Derms',
    },
    {
      condition: 'skin',
      link: '/reviews/',
      text: 'Reviews',
    },
    {
      condition: 'eyelashes',
      link: '/slather/',
      text: 'Slather',
    },
  ],
  tertiaryLinks: [
    {
      link: 'http://faq.apostrophe.com/',
      text: 'FAQ',
    },
    {
      link: 'https://boards.greenhouse.io/youhealthproviders',
      text: 'Careers',
    },
    {
      link: '/contact/',
      text: 'Contact Us',
    },
  ],
  socialLinks: [
    {
      link: 'http://www.instagram.com/hi_apostrophe',
      text: 'Instagram',
    },
    {
      link: 'http://www.facebook.com/hi.apostrophe',
      text: 'Facebook',
    },
    {
      link: 'http://www.twitter.com/hi_apostrophe',
      text: 'Twitter',
    },
  ],
};

const slatherLinks = {
  treatments: [
    {
      link: '/slather/deep-dive/',
      text: 'Deep Dive',
    },
    {
      link: '/slather/think-pieces/',
      text: 'Think Pieces',
    },
    {
      link: '/slather/education/',
      text: 'Education',
    },
    {
      link: '/slather/the-scoop/',
      text: 'The Scoop',
    },
  ],
  largeLinks: [
    {
      link: '/slather/about/',
      text: 'About Slather',
    },
  ],
  tertiaryLinks: [
  ],
  socialLinks: [
  ],
};

export {
  links,
  slatherLinks,
};
