import React from 'react';

const LogoInitial = () => (
  <svg className="logo-initial" xmlns="http://www.w3.org/2000/svg" width="40" height="48" viewBox="0 0 40 48">
    <title>Apostrophe text logo white</title>
    <desc>Apostrophe spelled out in text in white.</desc>
    <path className="logo-initial__path" fill="#FFF" fillRule="evenodd" d="M28.086 46.604l-5.539-14.475H10.906c-1.609 0-3.205.482-4.46 1.489-1.024.821-1.7 1.852-1.7 2.987 0 2.847 4.034 3.322 4.034 7.178C8.78 45.8 7 47.58 4.747 47.58 1.72 47.58 0 45.206 0 42.24c0-4.864 4.66-11.01 10.24-11.01 2.696 0 9.834-.015 11.956-.02l-.5-1.305-.798-1.96-6.248-16.332a1.012 1.012 0 0 1 .003-.73L18.541.997a1.01 1.01 0 0 1 .94-.64h1.449c.418 0 .794.257.944.648l17.465 45.599H28.086z"/>
  </svg>
);

const LogoInitialDark = () => (
  <svg className="logo-initial" xmlns="http://www.w3.org/2000/svg" width="40" height="48" viewBox="0 0 40 48">
    <title>Apostrophe text logo black</title>
    <desc>Apostrophe spelled out in text in black.</desc>
    <path className="logo-initial__path" fill="#333" fillRule="evenodd" d="M28.086 46.604l-5.539-14.475H10.906c-1.609 0-3.205.482-4.46 1.489-1.024.821-1.7 1.852-1.7 2.987 0 2.847 4.034 3.322 4.034 7.178C8.78 45.8 7 47.58 4.747 47.58 1.72 47.58 0 45.206 0 42.24c0-4.864 4.66-11.01 10.24-11.01 2.696 0 9.834-.015 11.956-.02l-.5-1.305-.798-1.96-6.248-16.332a1.012 1.012 0 0 1 .003-.73L18.541.997a1.01 1.01 0 0 1 .94-.64h1.449c.418 0 .794.257.944.648l17.465 45.599H28.086z"/>
  </svg>
);

export {
  LogoInitialDark,
};
export default LogoInitial;
