import React from 'react';

const FacebookFooter = () => (

    <svg width="26px" height="34px" viewBox="0 0 19 38" version="1.1" xmlns="http://www.w3.org/2000/svg">
        <title>Facebook</title>
        <g id="Page-2" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
            <g id="facebook-white"  fillRule="nonzero">
                <path d="M12.68,12.59 L12.68,8.07 C12.6799836,7.14605287 13.4260692,6.39549969 14.35,6.39 L18.78,6.39 L18.78,0 L11.51,0 C7.88119572,0.0144647844 4.85435052,2.77754205 4.51,6.39 C4.51,6.61 4.51,6.84 4.51,7.07 L4.51,12.59 L1.77635684e-15,12.59 L1.77635684e-15,18.83 L4.44,18.83 L4.44,37.66 L12.67,37.66 L12.67,18.83 L18.09,18.83 L18.87,12.64 L12.68,12.59 Z" id="Path"></path>
            </g>
        </g>
    </svg>

);

export default FacebookFooter;

