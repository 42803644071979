import React from 'react';
import PropTypes from 'prop-types';
import Link from 'components/utils/Link';
import {
  LargeLink,
  SmallLink,
} from './styles';

const MenuItem = ({ type, condition, link, text, onClick, index, solo, slather, node }) => {
  
  const linkComponents = {
    large: LargeLink,
    small: SmallLink,
    default: Link,
  };

  // eslint-disable-next-line no-prototype-builtins
  const MyLink = (linkComponents.hasOwnProperty(type) ? linkComponents[type] : linkComponents.default);
  // do not create a menu tab to Slather general (our unindexed category) articles
  if (node?.title === 'General'){
    return null;
  }
  return (
    <li>
      <MyLink
        solo={solo}
        index={index}
        hoverborder={condition ? 'true' : undefined}
        condition={condition && condition}
        to={node ? `/slather/${node.slug}` : link}
        onClick={onClick && onClick}
        target=""
      >
        {node ? node.title : text}
      </MyLink>
    </li>
  );
};

MenuItem.propTypes = {
  type: PropTypes.string,
  condition: PropTypes.string,
  link: PropTypes.string,
  text: PropTypes.string,
  onClick: PropTypes.func,
  index: PropTypes.number,
  solo: PropTypes.string,
  slather: PropTypes.bool,
};

export default MenuItem;
