/* eslint-disable max-statements */
import React from 'react';
import ContextConsumer from 'components/Context';
import { BannerWrapper, Banner } from './styles';
import Link from 'components/utils/Link';
import PropTypes from 'prop-types';

class AcneLaunch extends React.Component {
  render() {
    const { pathname } = this.props.location;
    const { content } = this.props;
    const { menuOpen } = this.props.context.data;
    const sanitizer = false;
    if (pathname === '/' && sanitizer) {
      if (sanitizer) {
        return (
          <BannerWrapper $menuOpen={menuOpen}>
            <Banner>
              <p>We’re donating 1% of profits to Vote.org until Election Day, Nov. 3rd.</p>
              <p><Link to="https://www.vote.org">Are you registered to vote?</Link></p>
            </Banner>
          </BannerWrapper>
        );
      }
      return (
        <BannerWrapper $menuOpen={menuOpen}>
          <Banner>
            <p>Apostrophe Acne Treatment is here!</p>
            <Link to="/get/acne-treatment/">Click here for clear skin</Link>
          </Banner>
        </BannerWrapper>
      );
    }
    if (content) {
      return (
        <BannerWrapper $menuOpen={menuOpen}>
          <Banner>
            <p>{content}</p>
          </Banner>
        </BannerWrapper>
      );
    }
    return null;
  }
}

const Consumer = props => (
  <ContextConsumer>
    {context => <AcneLaunch {...props} context={context} />}
  </ContextConsumer>
);

export default Consumer;

AcneLaunch.propTypes = {
  location: PropTypes.object,
  context: PropTypes.object,
  content: PropTypes.string,
};
