// Updates the condition if not already passed in
const conditionRegistry = {
  '/get/latisse/': 'LASH',
  '/get/latisse/starter-kit/': 'LASH',
  '/get/acne-treatment/': 'ACNE',
  '/get/acne-treatment-2/': 'ACNE',
  '/get/rosacea-treatment/': 'ROSE',
  '/get/tretinoin/': 'FILI',
};

// Overwrite promos (barely used)
const promoRegistry = {
  '/get/latisse/starter-kit/': 'STARTER_KIT',
};

// This list includes pages that should not contain a "condition" param
const conditionExclusions = [
  '/get/doxycycline/',
  '/get/minocycline/',
  '/get/azelaic-acid/',
  '/get/cephalexin/',
  '/get/clindamycin/',
  '/get/spironolactone/',
];

// Updates condition based on current page (used for older treatment pages)
const pageToCondition = {
  'ACNE_TREATMENT': 'ACNE',
  'ROSACEA': 'ROSE',
  'ROSACEA_TREATMENT': 'ROSE',
  'WRINKLES': 'FILI',
  'EYELASHES': 'LASH',
};

// Used to see if _FORMULA needs to be added
const pageToCompound = [
  'AZELAIC_ACID',
  'CLINDAMYCIN',
  'TRETINOIN',
  'HYDROQUINONE',
  'TAZAROTENE',
];

const pageToID = {
  'azelaic_acid': 'azelaicacidformula2',
  'clindamycin': 'clindamycinformula_v4',
  'doxycycline': 'doxycycline',
  'hydroquinone': 'hydroquinoneformula2',
  'minocycline': 'minocycline',
  'spironolactone': 'spiro',
  'spironolactone_for_acne': 'spiro',
  'tazarotene': 'tazaroteneformula2',
  'tretinoin': 'TRETINOINFORMULA36g',
  'latisse': '1552588763pages_commerce_sell5c8a9fdb5bd881898607205',
  'latisse_promo': '1552588763pages_commerce_sell5c8a9fdb5bd881898607205',
};

// Add new values to this object if you don't want them to be considered their own campaign
const pageToMedication = { 
  'LATISSE_PROMO': 'LATISSE'
};

export {
  conditionRegistry,
  promoRegistry,
  conditionExclusions,
  pageToCondition,
  pageToCompound,
  pageToID,
  pageToMedication,
};