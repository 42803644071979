import React from 'react';

const Close = () => (
  <svg version="1.1" viewBox="0 0 18 16" xmlns="http://www.w3.org/2000/svg">
    <title>Close X button</title>
    <desc>Black X symbol used to close windows or modals.</desc>
    <g fill="none" fillRule="evenodd" strokeLinecap="square">
      <g transform="translate(-70 -31)" stroke="#333">
        <g transform="translate(71 31)">
          <path d="m8 8l7.6154-7.6154-7.6154 7.6154-7.6154-7.6154 7.6154 7.6154zl-7.6154 7.6154 7.6154-7.6154 7.6154 7.6154-7.6154-7.6154z"/>
        </g>
      </g>
    </g>
  </svg>
);

export default Close;
