/* globals process */
const aws_config = {
  // ...
  'aws_appsync_graphqlEndpoint': `${process.env.GATSBY_AWS_APPSYNC_API_URL}`,
  'aws_appsync_region': 'us-west-2',
  'aws_appsync_authenticationType': 'API_KEY',
  'aws_appsync_apiKey': `${process.env.GATSBY_AWS_APPSYNC_API_KEY}`,
  // ...
};


export default aws_config;
