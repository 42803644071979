import React from 'react';
import styled, { css } from 'styled-components';
import { variables, media } from 'styleUtils';
import { rgba } from 'polished';
import Link from 'components/utils/Link';

const buttonColor = (props) => {
  if (props.color) {
    return variables[props.color];
  } else if (props.inverted) {
    return variables.white;
  }
  return variables.darkGrey;
};
/* eslint-disable-next-line no-nested-ternary */
const defaultButtonStyle = props => `
  border: none;
  display: inline-block;
  font-size: 18px;
  color: ${props.color || props.inverted ? variables.darkGrey : variables.white};
  /* eslint-disable-next-line no-nested-ternary */
  background-color: ${buttonColor(props)};
  border-radius: 2rem;
  letter-spacing: .5px;
  font-weight: 100;
  cursor: pointer;
  transition: background-color .5s ease, color .5s ease;
  padding-top: 20px;
  padding-bottom: 20px;
  padding-right: 28px;
  padding-left: 28px;
  text-align: center;

  :disabled, &.disabled {
    background-color: #d6d5d4;
    cursor: default;
    &:hover {
      background-color: #d6d5d4;
      color: ${variables.white};
    }
  }
  :hover {
    background-color: ${props.color ? rgba(variables[props.color], 0.8) : variables.midGrey};
    color: ${props.color ? variables.darkGrey : variables.white};
    transition: background-color .5s ease 0s, color .5s ease 0s;
  }
  :focus {
    outline: none;
  }

  ${props.color ? css`
    color: ${variables.darkGrey};
    background-color: ${variables[props.color]};
  ` : ''}

  ${props.large ? css`
    font-size: 18px;
    width: 100%;
    padding-top: 15px;
    padding-bottom: 15px;
    text-align: center;
  ` : ''}

`;

/* eslint-disable-next-line no-unused-vars */
const Button = styled.button`
  ${props => defaultButtonStyle(props)}
`;

/* eslint-disable-next-line no-unused-vars */
const LinkButton = styled(Link)`
  text-decoration: none;
  ${props => defaultButtonStyle(props)}
`;

const OutlinedButton = styled(Button)`
  background-color: transparent;
  color: ${variables.darkGrey};
  min-width: 150px;
  box-shadow: 0px 0px 0px 1px ${variables.lineColor};
  transition: box-shadow 0.3s ease 0s, border-color .3s ease 0s;
  :hover {
    background: transparent;
    color: ${variables.darkGrey};
    box-shadow: 0px 0px 0px 3px ${variables.darkGrey};
    border-color: ${variables.darkGrey};
    transition: box-shadow 0.3s ease 0s, border-color .3s ease 0s;
  }
`;

const InvertedOutlinedButton = styled(OutlinedButton)`
  box-shadow: 0px 0px 0px 1px ${variables.darkGrey};
  transition: background-color 0.5s ease 0s, color 0.5s ease 0s, box-shadow .5s ease 0s;
  background-color: transparent;
  min-width: 100px;
  padding: 9px 15px 11px;

  &:hover {
    color: ${variables.white};
    background: initial;
    background-color: ${variables.darkGrey};
    transition: background-color 0.3s ease 0s, color 0.3s ease 0s, box-shadow .5s ease 0s;
    border-color: none;
  }

  ${media.tablet`
    min-width: 150px;
    padding: 17px 22px 19px;
  `}

  ${media.desktop`
    min-width: 130px;
    padding: 13px 20px 15px;
  `}
`;

const SquareButton = styled(Button)`
  /* height: 3rem; */
  border-radius: 0;
  min-width: 200px;
  padding-left: 0;
  padding-right: 0;
  text-align: center;
  ${media.tablet`
    width: 160px;
    height: 3.75rem;
  `}
`;

const LinkStyledButton = styled(Button)`
  background: none !important;
  border: none;
  padding: 0 !important;
  text-decoration: underline;
  cursor: pointer;
  color: ${variables.darkGrey};
  &:hover {
    color: ${variables.midGrey};
  }
`;

export { LinkButton, OutlinedButton, SquareButton, InvertedOutlinedButton, LinkStyledButton };
export default Button;
