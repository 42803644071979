import React, { useEffect, useState } from 'react';

const Privacy = () => {

  const [showCPRA, setShowCPRA] = useState(null);
  const [isSellingData, setIsSellingData] = useState(null);

  // Callback to re-calculate consent preferences
  const onConsentChange = (e) => {
    setIsSellingData(
      !!e.target.getConsent().purposes.SaleOfInfo,
    );
  };

  const initialize = (airgap) => {

    // Determind if CPRA button should be shown
    let show_cpra = airgap.getRegimes().has('CPRA');
    setShowCPRA(show_cpra);

    // Check if the user is opted into sale of data
    setIsSellingData(!!airgap.getConsent().purposes.SaleOfInfo);

    // Ensure airgap was initialized
    if (!airgap || !window.transcend) {
      throw new Error(`Expected airgap and transcend to be initialized!`);
    }

    // Add listener on change to re-calculate consent preferences
    airgap.addEventListener('consent-change', onConsentChange);

  }

  // Wait for airgap to be ready
  useEffect(() => {
    window.airgap?.ready(initialize)

    // Remove event listener when component unmounts
    return () => window.airgap?.removeEventListener && window.airgap.removeEventListener(
      'consent-change',
      onConsentChange,
    )
  }, []);

  return (
    <div className="privacy">
      {isSellingData !== null && ( // Only show the button in California
        isSellingData ?
          ( showCPRA ?
            <span className="privacy-link" onClick={(event) => window.transcend?.doNotSell(event.nativeEvent)}>Do not sell or share my personal information</span>
            :
            <a href="https://privacy.apostrophe.com/policies">Do not sell or share my personal information</a>
          )
          :
          <span>We do not sell or share your personal information</span>
      )}
      <p>
        Request to access or delete your personal information by visiting our <a style={{ textDecoration: 'underline' }} href="https://privacy.apostrophe.com/policies">privacy center</a>.
      </p>
    </div>
  )
};

export default Privacy;
