import React, { memo, useEffect, useState, useRef } from 'react';
import PropTypes from 'prop-types';
import DropDownArrow from 'icons/DropDownArrow';
import { useSpring, a } from 'react-spring';
import ResizeObserver from 'resize-observer-polyfill';
import {
  Frame,
  Title,
  toggle,
  CaretWrapper,
  Content,
} from './styles';

function usePrevious(value) {
  const ref = useRef();
  // eslint-disable-next-line no-void
  useEffect(() => void (ref.current = value), [value]);
  return ref.current;
}

function useMeasure() {
  const ref = useRef();
  const [bounds, set] = useState({ left: 0, top: 0, width: 0, height: 0 });
  const [ro] = useState(() => new ResizeObserver(([entry]) => set(entry.contentRect)));
  useEffect(() => {
    if (ref.current) { ro.observe(ref.current); }
    return () => ro.disconnect();
  }, []);
  return [{ ref }, bounds];
}

const MenuTree = memo(({
  children,
  name,
  style,
  defaultOpen = false,
  menuOpen,
  setDropdown,
  value,
  dropDown,
  color,
}) => {
  const [isOpen, setOpen] = useState(false);
  const previous = usePrevious(isOpen);
  const [bind, { height: viewHeight }] = useMeasure();
  const { height, opacity, transform } = useSpring({
    from: { height: 0, opacity: 0, transform: 'translate3d(20px,0,0)' },
    to: { height: isOpen ? viewHeight : 0, opacity: isOpen ? 1 : 0, transform: `translate3d(${isOpen ? 0 : 20}px,0,0)` },
    config: { duration: 300 },
  });

  useEffect(() => {
    if (menuOpen && (value === dropDown)) {
      setTimeout(() => setOpen(true), 150);
    } else {
      setOpen(false);
    }
  }, [dropDown]);

  useEffect(() => {
    if (defaultOpen && menuOpen && (value === dropDown)) {
      setTimeout(() => setOpen(true), 200);
    } else {
      setOpen(false);
    }
  }, [menuOpen]);

  const handleClick = () => {
    if (!isOpen) {
      setDropdown(value);
      setTimeout(() => setOpen(true), 150);
    } else {
      setOpen(!isOpen);
    }
  };

  return (
    <Frame onClick={() => handleClick()}>
      <Title color={color} isOpen={isOpen} style={style}>
        <p>{name}</p>
        <CaretWrapper isOpen={isOpen} >
          <DropDownArrow style={{ ...toggle }} />
        </CaretWrapper>
      </Title>
      <Content style={{ opacity, height: isOpen && previous === isOpen ? 'auto' : height }}>
        <a.div style={{ transform }} {...bind}>
          {children}
        </a.div>
      </Content>
    </Frame>
  );
});

MenuTree.propTypes = {
  children: PropTypes.array,
  menuOpen: PropTypes.bool,
  defaultOpen: PropTypes.bool,
  color: PropTypes.string,
  style: PropTypes.string,
  setDropdown: PropTypes.func, 
  dropDown: PropTypes.number,
  value: PropTypes.number,
  name: PropTypes.string,
};

export default MenuTree;

