/* globals process */
import React from 'react';
import PropTypes from 'prop-types';
import { Wrapper, Container } from './styles';
import SignupForm from './SignupForm';
import SignupSuccess from './Success';
import APIGateway from 'helpers/apiGateway';

const content = {
  'general': {
    title: 'Still thinking about it? 💭',
    description: 'Sign up for our newsletter to get $10 off your Apostrophe medication. 🤑',
  },
  'blog': {
    title: 'Keep reading 🤓',
    description: 'Sign up for our newsletter to keep learning + receive $10 off Apostrophe meds. 💸',
  },
};
const DISMISS_HOURS = 12;
function determineDismissal(dismissedAt) {
  const compareTo = new Date();
  compareTo.setHours(compareTo.getHours() - DISMISS_HOURS);
  return new Date(dismissedAt) > compareTo;
}

const NO_SIGNUP = [
  '/slather',
];

const noNewsletterPages = [
  'medication-information',
  'informed-consent',
];
class Newsletter extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      busy: false,
      signedUp: false,
      dismissed: false,
      errorMsg: '',
      showNewsLetter: true,
      hide: false,
    };

    this.submitForm = this.submitForm.bind(this);
    this.dismissSignup = this.dismissSignup.bind(this);
    this.setNewsletterDisplay = this.setNewsletterDisplay.bind(this);
  }

  componentDidMount() {
    const { popUp } = this.props;
    // For dismissable banners,
    // Check if has already dismissed banner in the last 12 hours,
    // or has already signed up.
    this.setNewsletterDisplay();
    this.setDismissedFromLocalStorage();

    const { pathname } = location;
    const noSingup = find(NO_SIGNUP, path => pathname.includes(path));

    if (noSingup) {
      this.setState({
        hide: true,
      });
    }

    if (popUp) {
      setTimeout(() => {
        this.setState({ poppedUp: true });
      }, 15000);
    }
  }

  setNewsletterDisplay() {
    const { location: { pathname } } = this.props;
    const { showNewsLetter } = this.state;

    noNewsletterPages.forEach((path) => {
      if (showNewsLetter) {
        if (pathname.includes(path)) {
          this.setState({
            showNewsLetter: false,
          });
        }
      }
    });
  }

  setDismissedFromLocalStorage() {
    if (this.props.dismissable) {
      let shouldDismiss = localStorage.getItem('newsletter-signedUp');
      if (!shouldDismiss) {
        const dismissedAt = localStorage.getItem('newsletter-dismissedAt');
        if (dismissedAt) { shouldDismiss = determineDismissal(dismissedAt); }
      }
      if (shouldDismiss) { this.setState({ dismissed: shouldDismiss }); }
    }
  }

  submitForm(formData) {
    this.setState({ busy: true });
    const gateway = new APIGateway();
    gateway.request('newsletter-subscriptions/', 'POST', formData, (res) => {
      if (res.error) {
        this.setState({
          errorMsg: res.error,
          busy: false,
        });
      } else {
        localStorage.setItem('newsletter-signedup', true);
        this.setState({
          signedUp: true,
          errorMsg: '',
          busy: false,
        });
      }
    });
  }


  dismissSignup() {
    const timestamp = new Date();
    localStorage.setItem('newsletter-dismissedAt', timestamp, { domain: process.env.GATSBY_DOMAIN });
    this.setState({
      dismissed: true,
    });
  }

  render() {
    const { signedUp, dismissed, poppedUp, errorMsg, busy, showNewsLetter } = this.state;
    const { popUp, blog, dismissable } = this.props;
    if (!dismissed && showNewsLetter && !this.state.hide) {
      return (
        <Wrapper
          noPad
          blog={blog}
          popUp={popUp}
          poppedUp={poppedUp}
          dismissed={dismissed}
        >
          {signedUp
          && <SignupSuccess
            dismiss={this.dismissSignup}
            dismissable={dismissable}
          />
          }
          {!signedUp
          && <Container>
            <SignupForm
              busy={busy}
              errorMsg={errorMsg}
              submitForm={this.submitForm}
              content={blog ? content.blog : content.general}
              dismissable={dismissable}
              dismiss={this.dismissSignup}
            />
          </Container>
          }
        </Wrapper>
      );
    }
    return null;
  }
}

Newsletter.propTypes = {
  popUp: PropTypes.bool,
  dismissable: PropTypes.bool,
  blog: PropTypes.bool,
  location: PropTypes.object,
};

export default Newsletter;
