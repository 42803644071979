import React from 'react';

const DropDownArrow = () => (
  <svg width="8px" height="6px" viewBox="0 0 8 6" version="1.1" xmlns="http://www.w3.org/2000/svg" >
    <title>Drop Down arrow</title>
    <desc>Created with Sketch.</desc>
    <g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g id="Desktop-menu--selected-and-drop-down-opened-automatically" transform="translate(-276.000000, -117.000000)" fill="#333333">
        <polygon id="Triangle" points="280 117 284 123 276 123"></polygon>
      </g>
    </g>
  </svg>
);

export default DropDownArrow;
