import styled from 'styled-components';
import { variables, media } from 'styleUtils';

const Section = styled.div`
  margin: 0 auto;
  background: ${props => (props.dark ? variables.lightGrey : variables.white)};
  max-width: ${props => (props.inner ? '768px' : undefined)};
  padding-top: ${props => (props.noPadTop || props.noPad ? '0' : '60px')};
  padding-top: ${props => (props.smallPadTop && '20px')};
  padding-bottom: ${props => (props.noPadBottom || props.noPad || props.noPadNonDesk ? '0' : '60px')};
  ${media.tablet`
    padding-top: ${props => (props.noPadTop || props.noPad ? '0' : '80px')};
    padding-bottom: ${props => props.noPadBottom || props.noPad || props.noPadNonDesk ? '0' : '80px'};
    padding-bottom: ${props => props.smallPadBottom && '50px'};
  `}
  ${media.desktop`
    max-width: unset;
    padding-top: ${props => (props.noPadTop || props.noPad || props.noPadDesk || props.noPadDeskTop ? '0' : '120px')};
    padding-bottom: ${props => props.noPadBottom || props.noPad || props.noPadDesk ? '0' : '120px'};
    padding-top: ${props => (props.smallPadDesk && '40px')};
    padding-bottom: ${props => props.smallPadBottom && '50px'};
    padding-bottom: ${props => props.tinyPadBottom && '5px'};
  `}
`;

const SplitBackgroundSection = styled(Section)`
  @media(max-width: 767px) {
    padding-top: 0;
    padding-bottom: ${props => props.padBottom ? '100px' : '0'};
  }
  background: linear-gradient(
    to bottom,
    ${props => variables[props.top]} 0%,
    ${props => variables[props.top]} ${props => props.breakPoint ? props.breakPoint : '60%'},
    ${props => variables[props.bottom]} ${props => props.breakPoint ? props.breakPoint : '60%'},
    ${props => variables[props.bottom]} 100%
  );
`;

const TestimonialsSection = styled.div`
  background: white;
  padding-bottom: 60px;
  ${media.tablet`
    padding-top: 0;
    padding-bottom: 80px;
    background: linear-gradient(to bottom,
      ${variables.white} 0%,
      ${variables.white} 150px,
      ${variables.lightGrey} 150px,
      ${variables.lightGrey} 100%);
  `}

  ${media.desktop`
    padding-top: 0;
    padding-bottom: unset;
    background: linear-gradient(to bottom,
      ${variables.white} 0%,
      ${variables.white} 250px,
      ${variables.lightGrey} 250px,
      ${variables.lightGrey} 100%);
  `}
`;

export { SplitBackgroundSection, TestimonialsSection };
export default Section;
