import React from 'react';
import PropTypes from 'prop-types';

const defaultContextValue = {
  data: {
    // set your initial data shape here
    menuOpen: false,
    scrollBreak: 10,
  },
  /* eslint-disable-next-line no-empty-function */
  set: () => {},
};

const { Provider, Consumer } = React.createContext(defaultContextValue);

class ContextProvider extends React.Component {
  constructor() {
    super();

    this.setData = this.setData.bind(this);
    this.state = {
      ...defaultContextValue,
      set: this.setData,
    };
  }

  setData(newData) {
    this.setState(state => ({
      data: {
        ...state.data,
        ...newData,
      },
    }));
  }

  render() {
    return <Provider value={this.state}>{this.props.children}</Provider>;
  }
}

ContextProvider.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.object,
    PropTypes.array,
  ]),
};

export { Consumer as default, ContextProvider };
