import React from 'react';
import PropTypes from 'prop-types';

const Hamburger = ({ fill = '#333' }) => (
<svg width="25" height="15" viewBox="0 0 25 15" fill="none" xmlns="http://www.w3.org/2000/svg">
<title>Hamburger menu icon</title>
<desc>Three black lines signifying a hidden expanded menu.</desc>
<path id="Hamburger" d="M-0.5 1V0.5H-1V1H-0.5ZM-0.5 2H-1V2.5H-0.5V2ZM24.5 2V2.5H25V2H24.5ZM24.5 1H25V0.5H24.5V1ZM-0.5 6.75V6.25H-1V6.75H-0.5ZM24.5 6.75H25V6.25H24.5V6.75ZM24.5 7.75V8.25H25V7.75H24.5ZM-0.5 7.75H-1V8.25H-0.5V7.75ZM-0.5 12.75V12.25H-1V12.75H-0.5ZM24.5 12.75H25V12.25H24.5V12.75ZM24.5 13.75V14.25H25V13.75H24.5ZM-0.5 13.75H-1V14.25H-0.5V13.75ZM-0.5 1.5H0V0.5H-0.5V1.5ZM0 2V1H-1V2H0ZM0 1.5H-0.5V2.5H0V1.5ZM24 1.5H0V2.5H24V1.5ZM24.5 1.5H24V2.5H24.5V1.5ZM24 1V2H25V1H24ZM24 1.5H24.5V0.5H24V1.5ZM0 1.5H24V0.5H0V1.5ZM0 6.25H-0.5V7.25H0V6.25ZM24 6.25H0V7.25H24V6.25ZM24.5 6.25H24V7.25H24.5V6.25ZM25 7.75V6.75H24V7.75H25ZM24 8.25H24.5V7.25H24V8.25ZM0 8.25H24V7.25H0V8.25ZM-0.5 8.25H0V7.25H-0.5V8.25ZM-1 6.75V7.75H0V6.75H-1ZM0 12.25H-0.5V13.25H0V12.25ZM24 12.25H0V13.25H24V12.25ZM24.5 12.25H24V13.25H24.5V12.25ZM25 13.75V12.75H24V13.75H25ZM24 14.25H24.5V13.25H24V14.25ZM0 14.25H24V13.25H0V14.25ZM-0.5 14.25H0V13.25H-0.5V14.25ZM-1 12.75V13.75H0V12.75H-1Z" fill="black"/>
</svg>



);



Hamburger.propTypes = {
  fill: PropTypes.string,
};

export default Hamburger;
