/* eslint-disable no-nested-ternary */
/* eslint-disable max-statements */
/* eslint-disable no-undef */
import React, { Component, Fragment } from 'react';
import { navigate } from 'gatsby';
import PropTypes from 'prop-types';
import { ThemeProvider } from 'styled-components';
import { Transition } from 'react-transition-group';
import find from 'lodash/find';
import throttle from 'lodash/throttle';
import debounce from 'lodash/debounce';
import Logo from 'components/utils/icons/Logo';
import NavigationMenu from './Menu';
import PreventScroll from 'styledComponents/PreventScroll';
import Close from 'components/utils/icons/Close';
import Hamburger from 'components/utils/icons/Hamburger';
import scrollPosition from 'helpers/scrollPosition';
import ContextConsumer from 'components/Context';
import { registrationLinkFromLocation } from 'helpers/linkHelpers';
import logAmplitudeEvent from 'helpers/logAmplitudeEvent';
import BannerTemplate from 'components/banners/BannerTemplate';

import {
  Wrapper,
  MenuToggle,
  Nav,
  NavLink,
  MenuContainer,
  LogoContainer,
  Controls,
  PrimaryCTA,
  MenuOverlay,
  MenuWrapper,
} from './styles';

// Add Routes here to toggle a darkNav
const DARK_NAV = [
  '/apostrophe-privacy-policy/',
  '/terms-of-service/',
  '/sms-terms-of-service/',
  '/medical-notice-of-privacy-practices/',
  '/telehealth-consent/',
  '/consumer-health-data-privacy-policy/',
  '/blog/',
  '/get/',
  '/contact/',
  '/contact',
  '/dermatologists/',
  '/dermatologists',
  '/reviews/',
  '/reviews',
  '/informed-consent/',
  '/medication-information/',
  '/treat-yourself/',
  '/ingredients',
  '/pharmacy',
  '/shop/',
  '/philosophe/',
  '/pod/',
  '/friend/',
  '/products/',
  '/medications/',
  '/anti-aging-cream/',
  '/newdrop/',
  '/',
];

const NO_NAV = ['/a-team', '/slather'];

const PROD_BUTTON = ['/get/', '/shop/'];

const transitionStyles = {
  entered: {
    opacity: 1,
  },
  exited: {
    pointerEvents: 'none',
    opacity: 0,
  },
};

class Navigation extends Component {
  constructor(props) {
    super(props);

    // initialize nav state based on location so server-side rendering doesn't add in navbar
    // -> prevents brief flicker on client side of navbar being shown then disabled on componentDidMount
    const { location } = props;
    const { pathname } = location || {};
    const noNav = pathname && find(NO_NAV, (path) => pathname.includes(path));
    const prodButton = find(PROD_BUTTON, (path) => pathname.includes(path));
    const latisse = pathname.includes('/latisse');
    const hydro = pathname.includes('/get/hydroquinone/');
    let buttonText = 'Begin Visit';
    const shop = pathname.includes('shop');

    if (prodButton) {
      buttonText = shop
        ? latisse
          ? 'Get Latisse'
          : 'Get your prescription'
        : hydro
        ? 'Add to cart'
        : 'Get your prescription';
    } else {
      buttonText = shop
        ? latisse
          ? 'Get Latisse'
          : 'Get your prescription'
        : hydro
        ? 'Add to cart'
        : 'Get your prescription';
    }

    this.state = {
      scrolled: false,
      hide: Boolean(noNav),
      primaryCta: `${process.env.GATSBY_APP_BASE_URL}/register/`,
      buttonText,
      showBanner: false,
    };
    this.toggler = React.createRef();

    this.debouncedScroll = debounce(this.toggleNavScroll.bind(this), 100);
    this.throttledScroll = throttle(this.toggleNavScroll.bind(this), 250);
    this.bindScrollBehavior = this.bindScrollBehavior.bind(this);

    this.toggleMenu = this.toggleMenu.bind(this);
    this.navigateHome = this.navigateHome.bind(this);
  }

  componentDidMount() {
    const { pathname } = location;
    let primaryCta;
    if (pathname && pathname.includes('sunscreen')) {
      primaryCta = `${process.env.GATSBY_APP_BASE_URL}/yd/register/order-otcs/?otc_type=AP_SCREEN`;
    } else {
      primaryCta = registrationLinkFromLocation({ pathname });
    }

    const isSafari = window.safari !== undefined;
    const noNav = find(NO_NAV, (path) => pathname.includes(path));

    // stop showing banner after Jan 1, 2024 11:59pm PST
    const showBanner = 1706774399000 - Date.now() > 0;

    this.setState({
      hide: Boolean(noNav),
      primaryCta,
      showBanner: Boolean(showBanner),
    });

    window.addEventListener(
      'scroll',
      // Throttle always in safari to prevent jumpy animations.
      isSafari ? this.debouncedScroll : this.throttledScroll
    );
  }

  componentDidUpdate(prevProps, prevState) {
    const { menuOpen } = this.props.context.data;
    const { pathname } = this.props.location;
    const { pathname: prevPath } = prevProps.location;
    // Close helpscout if the menu has been opened
    if (menuOpen && prevState.menuOpen !== menuOpen && window.Beacon) {
      window.Beacon('close');
    }

    if (prevPath !== pathname) {
      // Update the navbar
      const hide = Boolean(
        pathname && find(NO_NAV, (path) => pathname.includes(path))
      );
      if (this.state.hide !== hide) {
        this.setState({ hide });
      }
      // Update the helpscout suggested docs on route change to keep results relevant
      if (window.Beacon) {
        window.Beacon('suggest');
        let primaryCta;
        if (pathname.includes('shop')) {
          primaryCta = registrationLinkFromLocation({ pathname });
        } else {
          primaryCta = registrationLinkFromLocation({ pathname });
        }
        this.setState({ primaryCta });
      }
    }
  }

  componentWillUnmount() {
    window.removeEventListener('scroll', this.debouncedScroll);
    window.removeEventListener('scroll', this.throttledScroll);
  }

  bindScrollBehavior(behavior) {
    const isSafari = window.safari !== undefined;
    if (isSafari) {
      return;
    }
    if (behavior == 'throttle') {
      window.addEventListener('scroll', this.throttledScroll);
      window.removeEventListener('scroll', this.debouncedScroll);
    } else if (behavior == 'debounce') {
      window.removeEventListener('scroll', this.throttledScroll);
      window.addEventListener('scroll', this.debouncedScroll);
    }
  }

  toggleNavScroll() {
    const currentPosition = scrollPosition();
    const { scrollBreak } = this.props.context.data;

    this.setState((prevState) => {
      if (currentPosition >= scrollBreak) {
        if (!prevState.scrolled) {
          this.bindScrollBehavior('debounce');
          return { scrolled: true };
        }
        return false;
      }
      if (prevState.scrolled) {
        this.bindScrollBehavior('throttle');
        return { scrolled: false };
      }
      return false;
    });
  }

  toggleMenu() {
    const { menuOpen } = this.props.context.data;
    this.props.context.set({ menuOpen: !menuOpen });
    const elements = document.getElementsByClassName('BeaconFabButtonFrame');
    if (elements && elements.length) {
      if (!menuOpen) {
        elements[0].style.display = 'none';
        document.body.style.overflow = 'hidden';
      } else {
        elements[0].style.display = 'block';
        document.body.style.overflow = 'visible';
      }
    }
  }

  navigateHome() {
    const { menuOpen } = this.state;
    if (menuOpen) {
      this.toggleMenu();
    }
    navigate('/');
  }

  render() {
    const { scrolled, primaryCta, showBanner } = this.state;
    const { location, hideMenu, hideAccount } = this.props;
    const { menuOpen } = this.props.context.data;
    const { pathname } = location;
    const isBody =
      ['butt', 'body', 'chest', 'back', 'start-fb', 'start-fb2'].some((o) =>
        pathname.includes(o)
      ) && !pathname.includes('medication-information');
    const isProduct = pathname.includes('products');
    const isLP = pathname.includes('/lp/');
    const isScreen = pathname.includes('sunscreen');

    function ToggleMenuAndLogToAmplitude(event, data) {
      menuOpen ? this.toggleMenu : null;
      logAmplitudeEvent(event, data);
    }

    const darkNav =
      find(DARK_NAV, (path) => !isBody && pathname.includes(path)) ||
      ['start-fb'].some((o) => pathname.includes(o)) ||
      ['start-fb2'].some((o) => pathname.includes(o));
    const theme = {
      dark: darkNav || menuOpen || pathname === '/',
      top: !scrolled,
      menuOpen,
    };
    if (!this.state.hide) {
      return (
        <ThemeProvider theme={theme}>
          <Fragment>
            {showBanner && <BannerTemplate location={location} />}
            <Wrapper menuOpen={menuOpen}>
              <Nav
                aria-label="primary-navigation"
                isBody={isBody}
                isProduct={isProduct}
              >
                {!hideMenu && (
                  <MenuContainer>
                    <MenuToggle ref={this.toggler} onClick={this.toggleMenu}>
                      {menuOpen ? <Close /> : <Hamburger />}
                    </MenuToggle>
                  </MenuContainer>
                )}
                <LogoContainer isProduct={isProduct} isLP={isLP}>
                  <Logo onClick={this.navigateHome} />
                </LogoContainer>
                <Controls className="controls" hideMenu={hideMenu}>
                  {!hideAccount && (
                    <NavLink
                      to={`${process.env.GATSBY_APP_BASE_URL}/login/`}
                      onClick={() =>
                        ToggleMenuAndLogToAmplitude('click', {
                          element: 'nav_CTA_login',
                        })
                      }
                    >
                      My account
                    </NavLink>
                  )}
                  <PrimaryCTA
                    to={primaryCta}
                    onClick={() =>
                      ToggleMenuAndLogToAmplitude('click', {
                        element: 'nav_CTA_start',
                      })
                    }
                    // disabled={isScreen}
                  >
                    {/* {isScreen ? 'Out of stock' : this.state.buttonText} */}
                    {this.state.buttonText}
                  </PrimaryCTA>
                </Controls>
              </Nav>
            </Wrapper>
            <Transition in={menuOpen} timeout={0.1}>
              {(state) => (
                <MenuWrapper style={{ ...transitionStyles[state] }}>
                  <MenuOverlay onClick={this.toggleMenu} />
                </MenuWrapper>
              )}
            </Transition>
            <NavigationMenu
              menuOpen={menuOpen}
              toggleMenu={this.toggleMenu}
              currentPath={pathname}
            />
            <PreventScroll stopScroll={menuOpen} preventClick={menuOpen} />
          </Fragment>
        </ThemeProvider>
      );
    }
    return null;
  }
}

Navigation.propTypes = {
  location: PropTypes.object,
  hideMenu: PropTypes.bool,
  hideAccount: PropTypes.bool,
  context: PropTypes.shape({
    data: PropTypes.shape({
      scrollBreak: PropTypes.number,
      menuOpen: PropTypes.bool,
    }),
    set: PropTypes.func,
  }),
};

/* eslint-disable-next-line react/display-name */
export default (props) => (
  <ContextConsumer>
    {(context) => <Navigation {...props} context={context} />}
  </ContextConsumer>
);
