/* globals Sentry */
function reportError(error, scopeHash) {
  Sentry.configureScope((scope) => {
    Object.keys(scopeHash).forEach((key) => {
      scope.setExtra(key, scopeHash[key]);
    });
  });
  Sentry.captureException(error);
}

export { reportError };
