import React, { Fragment } from 'react';
import Accreditation from './Accreditation';
import Caret from 'components/utils/icons/Caret';
import HimsHers from 'components/utils/icons/HimsHers';
import ComplexRender from 'components/utils/ComplexRender';
import Copyright from './Copyright';
import FooterLegalLinks from './LegalLinks';
import FooterMainLinks from './MainLinks';
import FooterMainLinksDesktop from './MainLinksDesktop';
import { LinkButton } from 'styledComponents/Button';
import LogoInitial from 'components/utils/icons/LogoInitial';
import Media from 'react-media';
import Privacy from './Privacy';
import PropTypes from 'prop-types';
import find from 'lodash/find';
import { registrationLinkFromLocation } from 'helpers/linkHelpers';
import { animateScroll as scroll } from 'react-scroll';
import { Wrapper, Row, HHLogoWrapper, HHLogoRow } from './styles';
import SocialMedia from './SocialMedia';
import logAmplitudeEvent from 'helpers/logAmplitudeEvent';
/*
  Note: isClient allows one version to load for SSR builds, then replaces it
  with dynamic break-pointed version once the react app loads.
*/

const NO_FOOT = ['/slather'];

const FREE_VISIT = ['/shop'];

const LATISSE = ['/latisse'];

const Footer = ({ location }) => {
  const noFoot = find(NO_FOOT, (path) => location.pathname.includes(path));
  let freeVisit = find(FREE_VISIT, (path) => location.pathname.includes(path));
  const latisse = find(LATISSE, (path) => location.pathname.includes(path));
  let primaryCta;
  if (location.pathname && location.pathname.includes('sunscreen')) {
    primaryCta = `${process.env.GATSBY_APP_BASE_URL}/yd/register/order-otcs/?otc_type=AP_SCREEN`;
  }
  if (latisse) {
    freeVisit = false;
  }
  if (noFoot) {
    return null;
  }
  return (
    <Wrapper>
      <nav label="footer-navigation" className="wrapper">
        <ComplexRender
          serverRender={
            <div className="container">
              <div className="section section-top">
                <LogoInitial />
                <HHLogoWrapper>
                  <HimsHers />
                </HHLogoWrapper>
                <SocialMedia />
                <LinkButton
                  className="begin-visit-link"
                  to={registrationLinkFromLocation(location)}
                  inverted="true"
                >
                  {freeVisit
                    ? 'Get your prescription'
                    : latisse
                    ? 'Get Latisse'
                    : 'Get your prescription'}
                </LinkButton>
              </div>
              <div className="section">
                <div>
                  <FooterMainLinks />
                </div>
                <Caret
                  onClick={() => scroll.scrollToTop()}
                  className="caret"
                  fill={'#000000'}
                />
              </div>
              <hr />
              <div className="section">
                <FooterLegalLinks />
                <Copyright />
              </div>
              <hr />
              <Privacy />
              <Accreditation />
            </div>
          }
          clientRender={
            <Fragment>
              <Media
                query="(max-width: 767px)"
                render={() => (
                  <div className="container">
                    <div className="section">
                      <LogoInitial />
                      <LinkButton
                        className="begin-visit-link"
                        to={
                          primaryCta
                            ? primaryCta
                            : registrationLinkFromLocation(location)
                        }
                        inverted="true"
                        onClick={() =>
                          logAmplitudeEvent('click', {
                            element: 'footer_CTA_start',
                          })
                        }
                      >
                        {freeVisit
                          ? 'Get your prescription'
                          : latisse
                          ? 'Get Latisse'
                          : 'Get your prescription'}
                      </LinkButton>
                    </div>
                    <HHLogoWrapper>
                      <HimsHers />
                    </HHLogoWrapper>
                    <div>
                      <FooterMainLinks />
                    </div>
                    <div style={{ marginTop: '25px' }}>
                      <SocialMedia />
                    </div>
                    <hr />
                    <div>
                      <FooterLegalLinks />
                      <div className="section">
                        <Copyright />
                        <Caret
                          onClick={() => scroll.scrollToTop()}
                          className="caret"
                          fill={'#000000'}
                        />
                      </div>
                      <Privacy />
                      <Accreditation />
                    </div>
                  </div>
                )}
              />
              <Media
                query="(min-width: 1280px)"
                render={() => (
                  <Fragment>
                    <div className="container primary">
                      <div className="section logos-iconography">
                        <div>
                          <LogoInitial />
                          <HHLogoWrapper>
                            <HimsHers />
                          </HHLogoWrapper>
                        </div>
                        <Caret
                          onClick={() => scroll.scrollToTop()}
                          className="caret"
                          fill={'#000000'}
                        />
                      </div>

                      <div className="section links-section">
                        <FooterMainLinksDesktop />
                      </div>
                      <div className="section right col">
                        <Row justify="space-between">
                          <SocialMedia isDesktop/>
                          <LinkButton
                            className="begin-visit-link"
                            to={registrationLinkFromLocation(location)}
                            inverted="true"
                            onClick={() =>
                              logAmplitudeEvent('click', {
                                element: 'footer_CTA_start',
                              })
                            }
                          >
                            {freeVisit
                              ? 'Get your prescription'
                              : latisse
                              ? 'Get Latisse'
                              : 'Get your prescription'}
                          </LinkButton>
                        </Row>
                      </div>
                    </div>
                    <div className="container legal-stuff">
                      <div className="section right col">
                        <FooterLegalLinks />
                      </div>
                    </div>
                    <div className="container divider">
                      <hr />
                      <div className="copyright-privacy">
                      <Copyright />
                        <Privacy />
                        </div>
                    </div>
                    <div className="container accreditations">
                      <div className="accreditation-row">
                        <Accreditation />
                      </div>
                    </div>
                  </Fragment>
                )}
              />
              <Media
                query="(min-width: 768px) and (max-width: 1279px)"
                render={() => (
                  <div className="container">
                    <div className="section section-top">
                      <LogoInitial />
                      <SocialMedia />
                      <LinkButton
                        className="begin-visit-link"
                        href={registrationLinkFromLocation(location)}
                        inverted="true"
                      >
                        {freeVisit
                          ? 'Get your prescription'
                          : latisse
                          ? 'Get Latisse'
                          : 'Get your prescription'}
                      </LinkButton>
                    </div>
                    <HHLogoRow>
                      <HHLogoWrapper>
                        <HimsHers />
                      </HHLogoWrapper>
                    </HHLogoRow>
                    <div className="section">
                      <div>
                        <FooterMainLinks />
                      </div>
                      <Caret
                        onClick={() => scroll.scrollToTop()}
                        className="caret"
                        fill={'#000000'}
                      />
                    </div>
                    <hr />
                    <div className="section">
                      <FooterLegalLinks />
                    </div>
                    <hr />
                    <Copyright />
                    <Privacy />
                    <Accreditation />
                  </div>
                )}
              />
            </Fragment>
          }
        />
      </nav>
    </Wrapper>
  );
};

Footer.propTypes = {
  location: PropTypes.object,
};
export default Footer;
