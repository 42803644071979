import React from 'react';
import PropTypes from 'prop-types';

const Account = ({ width, height, viewBox }) => {
  if (viewBox) {
    return (
      <svg xmlns="http://www.w3.org/2000/svg" width={width ? width : '26'} height={height ? height : '26'} viewBox={viewBox} version="1.1" >
        <title>Account profile image</title>
        <desc>Small black circle with white background containing a person figure used to represent the profile.</desc>
        <path fillRule="evenodd" d="M13 11.784a3.295 3.295 0 0 1-3.291-3.291A3.295 3.295 0 0 1 13 5.2a3.295 3.295 0 0 1 3.291 3.292A3.295 3.295 0 0 1 13 11.783m0-7.704a4.419 4.419 0 0 0-4.414 4.414A4.419 4.419 0 0 0 13 12.906a4.419 4.419 0 0 0 4.414-4.413A4.419 4.419 0 0 0 13 4.079m8.727 16.95c-1.657-4.221-4.993-6.9-8.695-6.9-3.735 0-7.064 2.696-8.711 6.952C2.343 18.96 1.122 16.122 1.122 13 1.122 6.45 6.451 1.122 13 1.122c6.55 0 11.878 5.328 11.878 11.878 0 3.097-1.202 5.913-3.151 8.03m-16.524.908c1.417-4.078 4.443-6.686 7.829-6.686 3.357 0 6.389 2.597 7.813 6.642A11.817 11.817 0 0 1 13 24.878a11.815 11.815 0 0 1-7.797-2.94M13 0C5.832 0 0 5.832 0 13s5.832 13 13 13 13-5.832 13-13S20.168 0 13 0"/>
      </svg>
    );
  }
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={width ? width : '26'} height={height ? height : '26'} version="1.1" >
      <title>Account profile image</title>
      <desc>Small black circle with white background containing a person figure used to represent the profile.</desc>
      <path fillRule="evenodd" d="M13 11.784a3.295 3.295 0 0 1-3.291-3.291A3.295 3.295 0 0 1 13 5.2a3.295 3.295 0 0 1 3.291 3.292A3.295 3.295 0 0 1 13 11.783m0-7.704a4.419 4.419 0 0 0-4.414 4.414A4.419 4.419 0 0 0 13 12.906a4.419 4.419 0 0 0 4.414-4.413A4.419 4.419 0 0 0 13 4.079m8.727 16.95c-1.657-4.221-4.993-6.9-8.695-6.9-3.735 0-7.064 2.696-8.711 6.952C2.343 18.96 1.122 16.122 1.122 13 1.122 6.45 6.451 1.122 13 1.122c6.55 0 11.878 5.328 11.878 11.878 0 3.097-1.202 5.913-3.151 8.03m-16.524.908c1.417-4.078 4.443-6.686 7.829-6.686 3.357 0 6.389 2.597 7.813 6.642A11.817 11.817 0 0 1 13 24.878a11.815 11.815 0 0 1-7.797-2.94M13 0C5.832 0 0 5.832 0 13s5.832 13 13 13 13-5.832 13-13S20.168 0 13 0"/>
    </svg>
  );
};

Account.propTypes = {
  width: PropTypes.string,
  height: PropTypes.string,
  viewBox: PropTypes.string,
};

export default Account;
