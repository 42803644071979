const { navigate } = require('gatsby');

/* eslint-disable-next-line no-undef */
require('babel-polyfill');

let nextRoute = ``;

// eslint-disable-next-line no-undef
exports.shouldUpdateScroll = ({
  routerProps: { location },
  getSavedScrollPosition,
}) => {
  const currentPosition = getSavedScrollPosition(location);
  window.scrollTo(...(currentPosition || [0, 0]));
  return false;
};

// eslint-disable-next-line no-undef
exports.onRouteUpdate = ({
  // eslint-disable-next-line no-unused-vars
  location,
}) => {
  // Don't track while developing.
  // eslint-disable-next-line no-undef
  nextRoute = location.pathname
  if (process.env.NODE_ENV === 'production' && typeof fbq === 'function') {
    // eslint-disable-next-line no-undef
    fbq('track', 'ViewContent');
  }
};

window.addEventListener("unhandledrejection", event => {
  if (/loading chunk \d* failed./i.test(event.reason)) {
       if (nextRoute) {
         window.location.pathname = nextRoute
      }
   }
});

exports.onClientEntry = () => {
  window.onload = () => { 
    const gatsbyFocusWrapper = document.getElementById('gatsby-focus-wrapper')
    if (gatsbyFocusWrapper) {
      gatsbyFocusWrapper.removeAttribute('style')
      gatsbyFocusWrapper.removeAttribute('tabIndex')
    }
   }
}