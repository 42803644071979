import styled from 'styled-components';

const BannerWrapper = styled.div`
  position: sticky;
  top: 0;
  z-index: 100;
  display: ${props => props.menuOpen ? 'none' : ''};
`;

export {
  BannerWrapper,
};
