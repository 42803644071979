/* eslint-disable consistent-return */
/* eslint-disable no-undef */
/* eslint-disable max-lines */
import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import Close from 'components/utils/icons/Close';
import Account from 'components/utils/icons/Account';
import { CSSTransition } from 'react-transition-group';
import { checkIfSafari } from 'helpers';
import { LinkButton } from 'styledComponents/Button';
import { Location } from '@reach/router';
import { registrationLinkFromLocation } from 'helpers/linkHelpers';
import Logo from 'components/utils/icons/Logo';
import MenuItem from '../MenuItem';
import MenuTree from '../MenuTree';
import LogoInitial from 'icons/LogoInitial';
import logAmplitudeEvent from 'helpers/logAmplitudeEvent';
import { navigate } from 'gatsby';
import {
  links,
  slatherLinks,
} from 'content/links';
import {
  Menu,
  TertiaryNavigation,
  SocialLinks,
  Divider,
  MenuToggle,
  MenuContent,
  MyAccountButton,
  Frame,
  ButtonsWrapper,
  MyAccountSVG,
  LogoContainer,
} from './styles';

const NavigationMenu = ({ menuOpen, toggleMenu, slather, SlatherLogo, categories }) => {

  const [isSafari, setIsSafari] = useState(false);
  const [textIndex, setTextIndex] = useState();
  const [dropDown, setDropdown] = useState(1);
  const transitionInterval = useRef();
  const close = useRef();
  const mainFocus = useRef();
  const menu = useRef();

  useEffect(() => {

    /* Force focus to menu each time the menu opens */
    if (!menuOpen) {
      close.current.focus();
    }
  }, [menuOpen]);

  useEffect(() => {
    if (textIndex === links.treatments.length) {
      clearInterval(transitionInterval.current);
    }
  }, [textIndex]);

  
  const setSiblingAriaHidden = (node, isHidden) => {

    let nextSibling = node.nextElementSibling;
    while(nextSibling) {
        nextSibling.ariaHidden = String(isHidden)
        nextSibling = nextSibling.nextElementSibling;
    }

    let prevSibling = node.previousElementSibling;
    while(prevSibling) {
        prevSibling.ariaHidden = String(isHidden)
        prevSibling = prevSibling.previousElementSibling;
    }

    node.ariaHidden = String(!isHidden)

  }


  useEffect(() => {
    const isSafari = checkIfSafari();
    setIsSafari(isSafari);

    // Make all siblings aria hidden/visible
    menu.current && setSiblingAriaHidden(menu.current, !!menuOpen)

    if (menuOpen) {

      // Set main element focus
      mainFocus.current && mainFocus.current.focus()

      setTimeout(() => {
        setTextIndex(0);
        transitionInterval.current = setInterval(() => {
          setTextIndex(state => (state + 1) % (links.treatments.length + 1));
        }, 300);
      }, 500);
      return () => {
        if (transitionInterval.current) {
          clearInterval(transitionInterval.current);
        }
      };
    }
  }, [menuOpen]);

  const navigateHome = () => {
    if (menuOpen) {
      toggleMenu();
    }
    navigate('/');
  };

  const navigateSlather = () => {
    if (menuOpen) {
      toggleMenu();
    }
    navigate('/slather/');
  };

  let largeLinks,
    products,
    socialLinks,
    tertiaryLinks;
  if (slather) {
    largeLinks = slatherLinks.largeLinks;
    tertiaryLinks = links.tertiaryLinks;
    socialLinks = links.socialLinks;
    products = categories;
  } else {
    products = links.products;
    largeLinks = links.largeLinks;
    tertiaryLinks = links.tertiaryLinks;
    socialLinks = links.socialLinks;
  }

  return (
    <CSSTransition in={menuOpen} timeout={0.3} classNames="menu">
      <Menu ref={menu} style={{display: !menuOpen && "none"}} slather={slather} aria-label="navigation-menu">
        <MenuToggle className="safari" $safari={isSafari} $slather={slather} ref={close} onClick={toggleMenu} aria-label="Close Menu">
          <Close />
        </MenuToggle>
        <MenuContent slather={slather} >
          <LogoContainer slather={slather} onClick={slather ? navigateSlather : navigateHome}>
            {slather ? <SlatherLogo onClick={navigateSlather} /> : <Logo onClick={navigateHome} />}
          </LogoContainer>
          {!slather && <>
            <ButtonsWrapper started>
              <Location>
                {location => (
                  <LinkButton
                    style={{ backgroundColor: 'white', border: '1.5px solid #333333', color: '#333333', textAlign: 'center', paddingLeft: '28px', marginRight: '15px' }}
                    ref={mainFocus}
                    to={ registrationLinkFromLocation(location)}
                    onClick={() => logAmplitudeEvent("click", {"element": "hamburger-dropdown_CTA_start"})}
                  >
                    {'Get started'}
                  </LinkButton>
                )}
              </Location>
            </ButtonsWrapper>
            <ButtonsWrapper>
              <MyAccountButton to={`${process.env.GATSBY_APP_BASE_URL}/login`} onClick={() => logAmplitudeEvent("click", {"element": "hamburger-dropdown_CTA_login"})}>
                <MyAccountSVG>
                  <Account width="16" height="16" viewBox="0 0 26 26" />
                </MyAccountSVG>
                  My account
              </MyAccountButton>
            </ButtonsWrapper>
          </>}
          <MenuTree
            setDropdown={setDropdown}
            menuOpen={menuOpen}
            name={slather ? 'Categories' : 'Our Products'}
            defaultOpen={true}
            value={1}
            dropDown={dropDown}
            color={'#fff980'}
          >
            {products.map((item, i) => <MenuItem
              key={i}
              index={i}
              type="large"
              {...item}
              onClick={toggleMenu}
              slather={slather}
            />
            )}
          </MenuTree>
          <Frame>
            {largeLinks.map((item, i) => <MenuItem
              key={i}
              solo={'true'}
              index={i}
              type="large"
              {...item}
              onClick={toggleMenu}
            />
            )}
          </Frame>
          {slather && <>
            <ButtonsWrapper slather started>
              <Location>
                {location => (
                  <LinkButton
                    style={{ backgroundColor: 'white', border: '1.5px solid #333333', color: '#333333', textAlign: 'center', paddingLeft: '28px', marginRight: '15px' }}
                    ref={mainFocus}
                    to={ registrationLinkFromLocation(location)}
                  >
                    {'Get started'}
                  </LinkButton>
                )}
              </Location>
            </ButtonsWrapper>
            <ButtonsWrapper slather >
              <MyAccountButton to={'/'}>
                <MyAccountSVG>
                  <LogoInitial width="20" height="20" viewBox="0 0 26 26" />
                </MyAccountSVG>
                  Home
              </MyAccountButton>
            </ButtonsWrapper>
          </>}
        </MenuContent>
        <Divider />
        <TertiaryNavigation slather={slather}>
          <ul>
            {tertiaryLinks.map((item, i) => (
              <MenuItem
                key={i}
                type="small"
                onClick={toggleMenu}
                {...item}
              />
            ))
            }
          </ul>
        </TertiaryNavigation>
        <SocialLinks>
          <ul>
            {socialLinks.map((item, i) => <MenuItem key={i} {...item} />)}
          </ul>
        </SocialLinks>
      </Menu>
    </CSSTransition>
  );
};

NavigationMenu.propTypes = {
  toggleMenu: PropTypes.func,
  menuOpen: PropTypes.bool,
  currentPath: PropTypes.string,
  slather: PropTypes.bool,
  SlatherLogo: PropTypes.func,
  categories: PropTypes.array,
};

export default NavigationMenu;

