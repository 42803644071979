/*
  logAmplitudeEvent:
  Takes params event (string) & data (dict/obj) and pushes them to the datalayer
  Google Tag Manager looks for all pushes to the datalayer with custom event "amplitude"
  Google Tag Manager will automatically push the datalayer obj to amplitude with event: event and event attributes: data  
*/
function logAmplitudeEvent(event, data = {}) {

    try {
      if (typeof window !== 'undefined') {
        
        window.dataLayer = window.dataLayer || [];
        
        window.dataLayer.push({ 
          event: "amplitude",
          amplitude_event: event,
          amplitude_data: data,
  
        });
      }
    } catch { }
    
  }
  
  export default logAmplitudeEvent
  
  