import isUndefined from 'lodash/isUndefined';

const scrollPosition = () => {
  if (isUndefined(window) || isUndefined(document)) { return 0; }
  return Math.max(
    window.pageYOffset,
    document.documentElement.scrollTop,
    document.body.scrollTop
  );
};

export default scrollPosition;
