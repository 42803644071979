/* globals process */
import React from 'react';
import PropTypes from 'prop-types';
import { Link as GatsbyLink } from 'gatsby';

// Since DOM elements <a> cannot receive activeClassName,
// destructure the prop here and pass it only to GatsbyLink
const Link = React.forwardRef(
  ({ children, to, activeClassName, ...other }, ref) => {
    // Used for robots.txt
    const noFollow = [
      '/terms-of-service/',
      '/apostrophe-privacy-policy/',
      '/medical-notice-of-privacy-practices/',
      '/telehealth-consent/',
      '/sms-terms-of-service/',
      '/consumer-health-data-privacy-policy/',
    ];

    // This example assumes that any internal link (intended for Gatsby)
    // will start with exactly one slash, and that anything else is external.

    const internal = /^\/(?!\/)/.test(to);
    // Use Gatsby Link for internal links, and <a> for others
    if (internal) {
      if (!(to.includes('#orals') || to.includes('#topicals'))) {
        to += to.endsWith('/') ? '' : '/';
      }
      const useNoFollow = noFollow.includes(to);
      if (useNoFollow) {
        return (
          <GatsbyLink
            ref={ref}
            to={to}
            rel="nofollow"
            activeClassName={activeClassName}
            {...other}
          >
            {children}
          </GatsbyLink>
        );
      }
      return (
        <GatsbyLink
          ref={ref}
          to={to}
          activeClassName={activeClassName}
          {...other}
        >
          {children}
        </GatsbyLink>
      );
    }
    // Check if Link is routing to a ourDomain, to prevent adding _blank
    const ourDomain = to && to.includes(process.env.GATSBY_DOMAIN);

    // Check if link is disabled (currently just for out of stock)
    if (other.disabled) {
      return (
        <a ref={ref} onClick={() => false} {...other}>
          {children}
        </a>
      );
    }

    return (
      <a
        href={to}
        ref={ref}
        target={ourDomain ? undefined : '_blank'}
        rel={ourDomain ? undefined : 'noopener noreferrer'}
        {...other}
      >
        {children}
      </a>
    );
  }
);

export default Link;
Link.propTypes = {
  activeClassName: PropTypes.string,
  to: PropTypes.string,
  children: PropTypes.oneOfType([
    PropTypes.array,
    PropTypes.string,
    PropTypes.object,
  ]),
};
