/* globals process */
import { isEmpty, find, forEach } from 'lodash';
import 'url-search-params-polyfill';
import {
  conditionRegistry,
  promoRegistry,
  conditionExclusions,
  pageToCondition,
  pageToCompound,
  pageToID,
  pageToMedication,
} from 'content/queryParams.js';

function handleCampaignParams (pathname, currentPage) {
  if (!pathname || !currentPage) { return false; }
  // only add campaign queryParam on get/ or shop/
  if (!pathname.includes('get') && !pathname.includes('shop')) { return false; }
  // If currentPage is a "CONDITION", should return four letter, uppercase identifier i.e. [ LASH, FILI, ACNE, ROSE ]
  const hasCondition = find(pageToCondition, (_, key) => key === currentPage);
  if (hasCondition) {
    return pageToCondition[currentPage];
  }

  // Compounded medications: uppercase and "_FORMULA" added
  const isCompounded = pageToCompound.indexOf(currentPage.toUpperCase());
  if (isCompounded !== -1) {
    return `${currentPage.toUpperCase()}_FORMULA`;
  }

  const convertToMedication = find(pageToMedication, (_, key) => key === currentPage);
  if (convertToMedication) {
    return pageToMedication[currentPage];
  }

  return currentPage.toUpperCase();
}

function handleFBID (currentPage) {
  if (!currentPage) { return false; }
  // If currentPage has an associated FB id that wasn't passed in through the button, add that here
  return pageToID[currentPage];
}

function slatherShopRegistrationLink(queryParams) {
  let registerHref = `${process.env.GATSBY_APP_BASE_URL}/register/`;

  const params = {};

  if (!isEmpty(queryParams)) {
    if (queryParams.campaign === "SCREEN") {
      return `${process.env.GATSBY_APP_BASE_URL}/yd/register/order-otcs/?otc_type=AP_SCREEN`;
    }
    forEach(queryParams, (val, key) => {
      if (typeof val !== 'undefined' && val !== 'v2') {
        if (!params[key]) {
          params[key] = val;
        }
      }
    });
  }

  if (!isEmpty(params)) {
    const urlSearchParams = new URLSearchParams(params);
    registerHref += `?${urlSearchParams.toString()}`;
  }

  return registerHref;
}

// eslint-disable-next-line max-statements
function registrationLink(queryParams = {}, pathname = '') {
  let registerHref = `${process.env.GATSBY_APP_BASE_URL}/register/`;

  // Update base url for google shopping purchases
  if (queryParams.pprx) {
    registerHref = `${process.env.GATSBY_APP_BASE_URL}/register/gs/`;
  }

  const params = {};

  // Map into new params object (excluding undefined and v2 values / repeats) - this seems like an extra precaution right now but keeping just in case
  if (!isEmpty(queryParams)) {
    forEach(queryParams, (val, key) => {
      if (typeof val !== 'undefined' && val !== 'v2' && val !== false) {
        if (!params[key]) {
          params[key] = val;
        }
      }
    });
  }

  // Create the search params in URL format
  if (!isEmpty(params)) {
    // For the `/get/acne-treatment-2/` page, do not redirect users with the condition=ACNE
    // param so that they are able to select conditions as normal.
    if (params.campaign === "ACNE_TREATMENT_2" && params.condition === "ACNE") {
      delete params.condition;
    }
    const urlSearchParams = new URLSearchParams(params);
    
    registerHref += `?${urlSearchParams.toString()}`;
  }

  return registerHref;
}

// This function handles setting url params for different products / pages / urls (so we don't have to pass all into each button on a page)
function changeParamsByUrl(pathname, queryParams) {

  // Handle condition exclusion pages
  if ((pathname && conditionExclusions.includes(pathname))) {
    if (queryParams.condition) { 
      delete queryParams.condition;
    }
  }

  // Add Google Shopping flag (pprx=True - pre purchase rx) and add condition
  if (pathname && (pathname.includes('shop') || pathname.includes('custom-rx'))) {
    queryParams.pprx = 'True';
    if (!pathname.includes('latisse')) {
      queryParams.condition = 'ACNE';
    }
  }
  
  // Set the correct campaign for Body Acne
  if (pathname && (['butt', 'body', 'chest', 'back'].some(o => pathname.includes(o)))) {
    queryParams.campaign = "BODY_ACNE"
    queryParams.condition = 'ACNE';
    if (pathname.includes('shop')) {
      queryParams.campaign = "CLINDAMYCIN_BODY_FORMULA"
    }
  }
  
  // Handle shop latisse promos
  if (pathname && pathname.includes('/shop/latisse')) {
    queryParams.promo = 'LATISSE99';
    queryParams.condition = 'LASH';
  }

  // Handle podcast pages by pulling promo code from url
  if (pathname && (pathname.includes('pod') || pathname.includes('friend'))) {
    pathname = pathname.split('/');
    queryParams.promo = pathname[pathname.length - 2];
  }

  return queryParams;
};

// Main function called from any CTA buttons to handle creation of the correct registration link w/ URL Params
function registrationLinkFromLocation(location = null, queryParams = {}) {
  let pathname = location && location.pathname;

  if (!pathname && location && location.location) {
    pathname = location.location.pathname;
  }

  // If we cannot find a pathname default to stndard register page
  if (!pathname) { return `${process.env.GATSBY_APP_BASE_URL}/register/`; }

  // Handle separate OTC checkout url
  if (pathname && pathname.includes('sunscreen')) {
    return (`${process.env.GATSBY_APP_BASE_URL}/yd/register/order-otcs/?otc_type=AP_SCREEN`);
  }

  /* See /pages/treatments and/or /pages/get/ pages/get to see which paths will be handled here
  remove leading and trailing forward slashes, trim white space, split and pop last item in array to get current page name. */
  let currentPage = pathname.replace(/\//gi, ' ').trim().split(' ').pop();
  currentPage = currentPage.split('-').join('_').toLowerCase();

  // Set our query params if they weren't passed in (or keep them the same if passed through button) - businessLine goes to condition in the URL
  queryParams.condition = queryParams.businessLine ? queryParams.businessLine : conditionRegistry[pathname];
  queryParams.promo = queryParams.promo ? queryParams.promo : promoRegistry[pathname];
  queryParams.campaign = queryParams.campaign ? queryParams.campaign : handleCampaignParams(pathname, currentPage);
  queryParams.FBContentID = queryParams.FBContentID ? queryParams.FBContentID : handleFBID(currentPage);

  // Update the params with pathname specific url parameters
  queryParams = changeParamsByUrl(pathname, queryParams);

  if (queryParams.businessLine) {
    delete queryParams.businessLine;
  }

  // Create and return the actual link
  return registrationLink(queryParams, pathname);
}

export { registrationLink, registrationLinkFromLocation, slatherShopRegistrationLink };
