import styled from 'styled-components';
import { media } from 'styleUtils';

const BannerWrapper = styled.div`
  position: sticky;
  top: 0;
  z-index: 100;
  display: ${props => props.menuOpen ? 'none' : ''};
`;

const Banner = styled.div`
  background-color: #006368;
  width: 100%;
  text-align: center;
  padding: 12px;
  padding-bottom: 2px;
  display: flex;
  flex-direction: column;
  min-height: 100px;
  display: flex;
  align-items: center;

  ${media.tablet`
    display: flex;
    justify-content: center;
    padding: 20px;
    padding-bottom: 2px;
  `}
`;

const Title = styled.h1`
  color: #FFF;
  text-align: center;
  font-family: 'GT-Pressura';
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px; /* 133.333% */
  letter-spacing: 2px;
  margin: 10px 20px;
  text-transform: uppercase;

  ${media.tablet`
    display: flex;
    flex-direction: row;
    font-size: 20px;
    margin: 15px 0;
  `}

  .tag {
    background-color: #fff;
    color: #006368;
    font-weight: 600;
    margin: 0px 5px;
    padding: 1px;
  }

  .prefix {
    padding-right: 10px;
    ${media.tablet`
      padding-right: 40px;
    `}
  }
`;

const Disclaimer = styled.div`
  color: #FFF;
  text-align: center;
  font-family: Maax;
  font-size: 5px;
  font-style: italic;
  font-weight: 400;
  line-height: 6px; /* 120% */
  width: 280px;
  padding-bottom: 6px;

  ${media.tablet`
    font-size: 8.4px;
    line-height: 9px; /* 107.143% */
    padding-bottom: 12px;
    width: auto;
  `}
`;


export {
  BannerWrapper,
  Banner,
  Title,
  Disclaimer,
};
