import { media, variables } from 'styleUtils';

import Link from 'components/utils/Link';
import styled from 'styled-components';

export const Wrapper = styled.div`
  .wrapper {
    background-color: #000000;
    min-height: 500px;
    padding-top: 80px;
  }

  .container.legal-stuff {
    justify-content: flex-end;
  }

  .container.divider {
    ${media.desktop`
      display: block;
  `}
  }

  .container {
    color: white;
    margin: 0 auto;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    font-size: 0.875rem; // consistent size on all screens
    flex-direction: column;
    width: 90%;
    ${media.tablet`
      width: 668px;
    `}

    ${media.desktop`
      flex-direction: row;
      width: 1180px;
    `}

    .caret {
      cursor: pointer;
      &svg {
        path {
          fill: #000000 !important;
        }
      }
    }

    a {
      color: white;
      text-decoration: none;
      border-bottom: none !important;

      &.begin-visit-link {
        color: #333333;
        font-size: 0.875rem;
        min-width: 202px;
        letter-spacing: 0px;

        &:hover {
          color: white;
        }
      }

      &:hover {
        color: white;
      }
    }

    hr {
      margin: 1rem 0;
      color: #8b8a87 !important;
      background-color: #8b8a87 !important;
      width: 100%;
      height: 1px;
    }
    .section {
      display: flex;
      justify-content: space-between;
      ${media.tablet`
        margin: 20px 0;
        align-items: flex-end;
      `}

      ${media.desktop`
        flex-direction: column;
        margin: unset;
        align-items: unset;
      `}
    }

    .section-top {
      margin: 0 0 5px 0 !important;
      ${media.desktop`
        margin: unset;
      `}
    }

    .accreditation-row {
      width: 100%;
      display: flex;
      justify-content: space-between;
      align-items: center;
    }

    .links-section {
      ${media.desktop`
        flex-grow: 1;
        margin-left: 100px;
        margin-bottom: 50px;
      `}
    }

    .right {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: flex-end;
      &.col {
        flex-direction: column;
      }
      .links {
        ${media.desktop`
          a {
            padding-left: 2rem;
          }
        `}
      }
    }
    .legal-links {
      align-self: center;
      display: flex;
      flex-direction: row;
      margin: 10px 0;
      font-size: 0.6875rem;

      div {
        display: flex;
        flex-direction: column;
        width: 33%;
      }
      .legal-links-long-text {
        width: 55%;
      }
      a {
        color: #8b8a87 !important;
        line-height: 2;
        &:hover {
          color: white !important;
        }
      }
      ${media.tablet`
        flex-direction: row;
        margin: unset;
        font-size: 0.75rem;
        a {
          line-height: unset;
          padding-right: 2em;
        }
        div {
          flex-direction: row;
          width: unset;
        }
        .legal-links-long-text {
          width: unset;
        }
      `}

      ${media.desktop`
      width: 125%;
        a {
          padding-left: 1em;
          padding-right: unset;
        }
      `}
    }
    .site-links {
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      width: 100%;
      line-height: 2;

      ${media.tablet`
        flex-direction: row;
        width: 140%;
      `}
    }

    .site-links-desktop {
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      width: 100%;
      line-height: 2;
      gap: 3em;
      
      div {
        display: flex;
        flex-direction: column;
        
      }
    }
    
    .desktopSocialMedia {
      margin-right: 10em;
    }

    .links {
      display: flex;
      border-bottom: 1px solid #8b8a87;
      padding: 25px 0;
      white-space: nowrap;
      a {
        line-height: 2.5;
      }

      ${media.tablet`
        border-bottom: unset;
        padding: unset;
        width: 60%;
        justify-content: space-between;        
        a {
          line-height: unset;
        }
      `}
    }

    .links-primary {
      display: flex;
      flex-direction: column;
      ${media.tablet`
        width: 40%;
      `}

      .treatment-links {
        display: flex;
        flex-wrap: wrap;
        a {
          width: 33%;
          ${media.tablet`
            width: 100%;
          `}
        }
        ${media.desktop`
          flex-direction: column;
        `}
      }
    }

    .links-secondary {
      display: flex;
      flex-direction: column;
      width: 33%;
      ${media.tablet`
        min-width: 33%;
      `}
      ${media.desktop`
        width: unset;
      `}
    }

    .copyright-privacy {
      ${media.desktop`
      margin-top: 30px;
    `}
    }

    .privacy {
      color: ${variables.midGrey};
      font-size: 0.6875rem;
      a, span, p {
        color: #8b8a87;
      }
      privacy-link {
        cursor: pointer;
      }
      margin-top: 8px;
      p {
        margin: 8px 0 0 0;
        color: ${variables.midGrey};
      }
      ${media.desktop`
        font-size: 0.75rem;
        width: 490px;
        margin: 0px;
        p {
          margin: 0px;
        }

      `}
      
    }

    .logo-initial {
      ${media.tablet`
        width: 80px;
      `}
      ${media.desktop`
        width: unset;
      `}
    }
    
    .copyright {
      color: #8b8a87;
      font-size: 0.6875rem;
      ${media.desktop`
        font-size: .75rem;
      `}
    }
    .accred-section {
      display: flex;
      align-items: center;
      margin-bottom: 25px;
      div {
        margin: 1px 10px;
      }

      ${media.tablet`
        margin: 25px 0 50px;
        div {
          margin: 0 30px;
        }
      `}
      ${media.desktop`
        margin-bottom: 25px;
      `}

      div:first-of-type {
        margin-left: 0;
      }
      div:last-of-type {
        margin-right: 0;
      }
    }

    .legitscript {
      width: 71.5px;
      height: 50.9px;
      ${media.tablet`
        width: 115.6px;
        height: 82.6px;
      .gatsby-image-wrapper {
          height: 100px;
        }
      `}
    }

    .bbb {
      width: 137px;
      height: 28.4px;
      ${media.tablet`
        width: 230.6px;
        height: 48px;
      `}
    }

    .hipaa {
      width: 78.2px;
      height: 45.9px;
      ${media.tablet`
        width: 130.8px;
        height: 77.1px;
      `}
    }
  }
`;
export const Row = styled.div`
  display: flex;
  justify-content: ${({ justify }) => justify || 'left'};
  width: 100%;
  align-items: center;
  ${media.tablet`
    justify-content: ${({ justify }) => justify || 'center'};
    margin-left: 80px;
  `}
  ${media.desktop`
    margin-left: 0px;
  `}
`;

export const SVGLink = styled(Link)`
  margin: 0 10px;
  svg {
    g {
      fill: white;
    }
    &:hover {
      g {
        transition: all 0.3s ease;
        fill: #b4b4b4;
      }
    }
  }
`;

export const HHLogoWrapper = styled.div`
  width: 100px;
  margin-top: 0;
  ${media.tablet`
    width: 120px;
  `}
  ${media.desktop`
    width: 135px;
    margin-top: 26px;
  `}
`;

export const HHLogoRow = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
`;
