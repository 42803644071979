/* eslint-disable sort-vars */
import React from 'react';
import PropTypes from 'prop-types';
import LayoutWrapper from '../Wrapper';
import Navigation from 'components/Navigation';
import Footer from 'components/Footer';
import ThePossessive from 'components/NewsletterSignup/ThePossessive';
import MemorialDayBanner from 'components/banners/MemorialDay';

const MainLayout = ({ location, children, noNewsletter }) => (
  <LayoutWrapper>
    <MemorialDayBanner location={location} />
    <Navigation location={location} />
    {children}
    {!noNewsletter && (
      <ThePossessive location={location} />
    )}
    <Footer location={location}/>
  </LayoutWrapper>
);

MainLayout.propTypes = {
  location: PropTypes.object,
  children: PropTypes.oneOfType([
    PropTypes.object,
    PropTypes.array,
  ]),
  noNewsletter: PropTypes.bool,
};
export default MainLayout;
