import styled from 'styled-components';
import { variables, media } from 'styleUtils';
import Button from 'styledComponents/Button';

const Content = styled.div`
  margin-bottom: 1.5rem;
  ${media.desktop`
    margin-bottom: 0;
  `}

  h2 {
    text-align: center;
    font-size: 1.75rem;
    line-height: 1.29;

    ${media.tablet`
      font-size: 1.875rem;
      line-height: 1.2;
    `}

    ${media.desktop`
      margin-bottom: 10px;
    `}
  }

  p {
    text-align: center;
    color: ${variables.darkGrey};
    max-width: 300px;
    font-size: 1rem;
    margin: 0 auto;

    ${media.tablet`
      max-width: 100%;
    `}
  }
`;

const Form = styled.form`
  position: relative;
  display: flex;
  flex-direction: column;
  max-width: 300px;
  margin: 0 auto;

  ${media.tablet`
    max-width: 600px;
    flex-direction: row;
  `}

  ${media.desktop`
    margin-right: 0;
  `}

  .errorMsg {
    margin: 0 auto;
    color: ${variables.darkGrey};
    position: absolute;
    bottom: -1.5rem;
    left: 0;
    right: 0;
    font-size: 0.875rem;
  }

  input {
    height: 3rem;
    text-align: center;
    border: 0;

    &::placeholder {
      font-size: 0.875rem;
      line-height: 1.5;
    }

    &:focus {
      outline: none;
      &::placeholder {
        color: transparent;
      }
    }

    ${media.tablet`
      height: 3.75rem;
      text-align: left;
      padding-left: 1.25rem
      flex: 1;
    `}

    ${media.desktop`
      width: 430px;
      &::placeholder {
        font-size: 1rem;
      }
    `}
  }
`;

const SquareButton = styled(Button)`
  height: 3rem;
  border-radius: 0;

  ${media.tablet`
    width: 110px;
    height: 3.75rem;
  `}
`;

export { Content, Form, SquareButton };
