import styled, { css } from 'styled-components';
import { variables, media } from 'styleUtils';
import { LinkButton } from 'styledComponents/Button';
import Link from 'components/utils/Link';
import { rgba } from 'polished';

const navHeight = {
  sm: '54px',
  lg: '64px',
  topSm: '72px',
  topLg: '100px',
};

const mobilePadding = '22px';

const Wrapper = styled.div`
  width: 100%;
  max-width: 100vw;
  z-index: 600;
  position: fixed;
  ${props => props.menuOpen && 'display: none;'}
`;

const MenuWrapper = styled.div`
  pointer-events: auto;
  position: fixed;
  width: 100%;
  height: 100%;
  overflow: hidden;
  transition: opacity 0.2s ease;
  z-index: 100;
  ${props => props.slather && 'margin-top: -5px;'}
  ${media.tablet`
    ${props => props.slather && 'margin-top: -35px;'}
  `}
  ${media.desktop`
    ${props => props.slather && 'margin-top: -45px;'}
  `}
  a {
    border-bottom: none;
  }
`;

const MenuOverlay = styled.div`
  width: 100%;
  height: 100%;
  z-index: 500;

  position: fixed;
  background-color: ${({ condition }) => {
    const color
      = condition && variables[condition]
        ? variables[condition]
        : variables.lightGrey;
    return `${rgba(color, 0.75)}`;
  }};
`;

const MenuContainer = styled.div`
  position: absolute;
  height: 100%;
  display: flex;
  width: 50px;
  justify-content: center;
  right: 5px;

  @media (min-width: 768px) {
    left: 34.5px;
  }

  ${({ theme }) => theme.top
    && css`
      height: 90%;
    `}
`;

const MenuToggle = styled.button`
  pointer-events: auto !important;
  outline: none;
  width: 50px;

  cursor: pointer;
  svg {
    transition: all .2s linear;
    margin-top: 5px;
    height: 13px;
    width: 16px;

  }

  svg {
    @media (min-width: 768px) {
      height: 12.75px;
      width: 25px;
    }
  }

  svg:hover  {
    transition: all .2s linear;
    pointer-events: auto;
    width: 23px;
    height: 23px;
  }

  ${({ theme }) => theme.top
    && css`
      svg {
        margin-top: 10px;
      }
    `}

  /* Don't show close button when menu is open on large screen - menu has its own button. */
  ${({ theme }) => theme.menuOpen
    && css`
      @media (min-width: 768px) {
        svg {
          display: none;
        }
      }
    `}
`;

const LogoContainer = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  padding-left: 22px;
  ${props => props.isBody && css`
    position: absolute;
    top: 0;
    margin-left: -82px;
    left: 50%;
  `};
  @media (min-width: 768px) {
    padding-left: 0;
    justify-content: left;
    ${props => props.isBody && css`
      position: absolute;
      top: 0;
      margin-left: -82px;
      left: 10%;
    `};
  }
  @media (min-width: 1280px) {
    ${props => !props.isLP && css`
      justify-content: center;
    `};
    ${props => props.isLP && css`
      align-items: center;
    `};
    ${props => props.isBody && css`
      position: absolute;
      top: 0;
      margin-left: 0px;
      left: 0%;
      svg {
        path {
          ${props => props.isProduct ? 'fill: #white;' : 'fill: #333333;'};
        }
      }
    `};
  }

  @media (min-width: 1640px) {
    ${props => props.isBody && props.isProduct && css`
      svg {
        path {
          fill: #333333;
        }
      }
    `}
  }

  svg {
    width: 113px;
    height: 25px;
    cursor: pointer;
    @media (min-width: 768px) {
      width: 168px !important;
      height: 29.58px !important;
      margin-left: 92px;
      ${props => props.isLP && css`
        margin-left: 50px;
      `};
    }
  }
`;

const Controls = styled.div`
  position: absolute;
  right: 3px;
  ${({ theme }) => theme.top
    && !theme.menuOpen
  && css`
    right: ${props => props.hideMenu ? '22px' : '60px'};
  `}
  top: 0;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  letter-spacing: 0.5px;
  font-size: 0.875rem;
  @media (min-width: 768px) {
    right: 35px;
  }

  ${({ theme }) => (theme.top || theme.menuOpen)
    && css`
      @media (min-width: 768px) {
        right: 35px !important;
      }
    `}
`;

const NavLink = styled(Link)`
  padding: 0 20px;
  display: none;

  color: ${({ theme }) => theme.top && !theme.dark ? variables.white : variables.darkGrey};
  border-bottom: none !important;

  @media (min-width: 768px) {
    display: flex;
  }
`;

const roundedCTA = darkTheme => `
    /* override back to original border radius */
    border-radius: 2rem;
    height: 2.5em;
    padding: 0 1rem !important;
    display: none;

    @media (min-width: 375px) {
      padding: 0 2em;
    }

    @media (min-width: 768px) {
      padding: 0 2em;
      height: 3.5em;
      padding: 0 1.5rem !important;
    }


    color: ${darkTheme ? variables.white : variables.darkGrey};
    background-color: ${darkTheme ? variables.darkGrey : variables.white};
  `;

const PrimaryCTA = styled(LinkButton)`
  @media (max-width: 324px) {
    font-size: 12px;
  }
  display: flex;
  color: ${variables.white};
  background-color: ${variables.darkGrey};
  height: 2.5em;
  padding: 0 1rem !important;
  font-size: 14px;

  align-items: center;

  border-bottom: none;
  border-radius: 2rem;

  @media (min-width: 375px) {
    padding: 0 2em;
  }

  @media (min-width: 768px) {
    display: flex;
    width: 201px;
    font-family: 'Maax';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 14px;
    text-align: center;
    padding: 0 2em;
    height: 3.5em;
    padding: 0 1.5rem !important;
  }

  &:hover {
    color: ${variables.white};
    background-color: ${variables.midGrey};
  }

  ${({ theme }) => theme.top
    && !theme.menuOpen
    && css`
      ${roundedCTA(theme.dark)}
    `}

  @media(min-width: 768px) {
    ${({ theme }) => theme.menuOpen
      && css`
        ${roundedCTA(theme.dark)}
      `}
  }
`;

const transparentNav = theme => `
    background-color: transparent;
    border: none;
    color: ${theme.dark ? variables.darkGrey : variables.white} !important;
    transition: all .5s ease, background-color 0s;
    height: ${navHeight.topSm};

    @media (min-width: 768px) {
      height: ${navHeight.topLg};
    }
    path {
      fill: ${theme.dark ? variables.darkGrey : variables.white};
    }
  `;

const Nav = styled.nav`
  pointer-events: auto !important;
  position: relative;
  transition: all 0.5s ease;

  background-color: ${variables.white};
  color: ${variables.darkGrey};
  height: ${navHeight.sm};
  border-bottom: 1px solid ${variables.lightGrey};

  ${props => props.isBody && css`
    width: 100%;
    .controls { 
      display: none;
    }
  `};

  path {
    fill: ${variables.darkGrey};
  }

  @media (min-width: 768px) {
    height: ${navHeight.lg};
  }

  ${({ theme }) => theme.top
    && !theme.menuOpen
    && css`
      ${transparentNav(theme)}
    `};

  @media (min-width: 768px) {
    ${({ theme }) => theme.menuOpen
      && css`
        ${transparentNav(theme)}
      `};
  }
`;

export {
  MenuToggle,
  Wrapper,
  Nav,
  MenuContainer,
  LogoContainer,
  NavLink,
  Controls,
  PrimaryCTA,
  MenuOverlay,
  MenuWrapper,
  navHeight,
};
