/* eslint-disable no-nested-ternary */
/* eslint-disable implicit-arrow-linebreak */
import styled, { css } from 'styled-components';
import { variables, media } from 'styleUtils';
import { MenuToggle as NavMenuToggle, navHeight } from '../styles';
import { LinkButton } from 'styledComponents/Button';

const menuGutterMobile = '20px';

// extend same toggle element from main nav.
const MenuToggle = styled(NavMenuToggle)`
  height: 80px;
  position: absolute;
  top: -8px;
  right: 25px;
  ${props => props.slather && css`
    top: 5px;
  `}
  flex-grow: 0;
  padding-left: 35px;
  height: ${navHeight.sm};
  @media (min-width: 768px) {
    /* ${props => props.slather && 'padding-bottom: 20px;'} */
    width: 100px;
    position: relative;
    padding-left: 60px;
    height: ${navHeight.lg};
    display: block;
    svg {
      display: block !important;
    }
  }

  ${({ theme }) => theme.top
    && css`
      height: ${navHeight.topSm};
      @media (min-width: 768px) {
        height: ${navHeight.topLg};
      }
    `}
  /* ${props => props.slather && props.safari && css`
    padding-left: 29px;
    height: 75px;
  `}
  ${props => props.slather && css`@media not all and (min-resolution:.001dpcm) { @supports (-webkit-appearance:none) and (stroke-color:transparent) {
      &.safari {
        padding-left: 29px;
        height: 75px;
      }
    }}`
} */
`;

const MyAccountButton = styled(LinkButton)`
  padding: 0.75rem 1rem;
  display: flex;
  height: 100%;
  width: 200px !important;
  align-items: center;
  margin: 0;
  font-size: 10px;
  padding-left: 30px;
`;

const Menu = styled.nav`
  z-index: 1000;
  overflow: hidden;
  pointer-events: auto;
  background-color: white;
  min-width: 100%;
  height: auto;
  left: -100%;
  margin-top: 0px;
  padding: 30px 0;
  padding-top: 0;
  @media (min-width: 768px) {
    left: -400px;
    min-width: 340px;
    margin-top: 0;
  }
  ${props => props.slather && css`
    background-color: ${variables.slatherColor};
    min-width: 70%;
    margin-top: -5px;
    ${media.tablet`
      margin-top: -35px;
    `}
    ${media.desktop`
      margin-top: -45px;
    `}
  `}
  /* ${props => props.slather && css`
      min-height: 280px;
      margin-top: 20px;
      margin-left: 20px;
      padding-top: 20px;
      border-radius: 40px;
    ${media.tablet`
      min-height: 320px;
      margin-top: 0px;
      margin-left: 20px;
      padding-top: 30px;
      border-radius: 60px;
      min-width: 35%;
    `}
    ${media.desktop`
      display: none;
    `}
  `} */

  /* ${props => props.slather && 'height: 36vh;'} */
  position: fixed;
  overflow-y: hidden;
  transition: left 0.2s ease-in, opacity 0.2s ease;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  &.menu-enter-done {
    left: 0;
    opacity: 1;
  }

  &.menu-exit-done {
    opacity: 0;
    left: -100%;
    @media (min-width: 768px) {
      left: -400px;
    }
  }
  /* ${props => props.slather && 'background-color: #fdfdf9 !important;'} */
`;

const MenuContent = styled.div`
  max-width: 375px;
  display: block;
  padding-top: 0px;
  height: auto;
  margin-top: 20px;
  padding-left: 0px;
  li {
    list-style-type: none;
    color: #333333;
  }
  ${media.tablet`
    margin-top: 0px;
  `}
  /* ${props => props.slather && css`
    height: calc(100vh - 100px);
    padding-top: 30px;
    padding-top: 0;
    flex-direction: column;
    margin-left: 11px;
    padding-left: 0;
    ${media.tablet`
      margin-left: 0;
    `}
    a {
      font-family: 'GT-Pressura';
      text-transform: uppercase;
    }
  `} */
`;

const SocialLinks = styled.div`
  display: none;
  ${media.tablet`
    display: block;
  `}
  margin-bottom: 2rem;
  width: 100%;
  ul {
    padding-left: 46px;
    padding-right: 50px;
    display: flex;
    justify-content: space-between;
  }
  li {
    padding-left: 0 !important;
    display: inline-block;
  }
  a {
    border-bottom: none;
    font-size: 0.875rem !important;
    color: ${variables.midGrey};
    &:hover {
      color: ${variables.darkGrey};
    }
  }
`;
const Divider = styled.hr`
  margin-left: 20px;
  margin-right: 20px;
  margin-bottom: 1rem;
  background-color: #333333;
  margin-top: 20px;
  ${props => props.slather && 'display: none;'}
  ${media.tablet`
    margin-right: 50px;
    margin-left: 46px;
  `}
`;
const TertiaryNavigation = styled.div`
  width: 100%;
  ul {
    list-style-type: none;
    display: flex;
    justify-content: space-between;
    padding-left: ${menuGutterMobile};
    padding-right: ${menuGutterMobile};
  }

  /* ${props => props.slather && 'display: none;'} */

  @media (min-width: 767px) {
    margin-bottom: 2rem;
    ul {
      padding-left: 0;
      padding-right: 0;
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
    }
    li {
      padding-left: 0 !important;
      display: inline-block;
    }
  }
`;

const RightSide = styled.div`
  width: 56%;
  ul {
      list-style-type: none;
  }
  a {
    padding-left: 25px;
  }
`;

const LeftSide = styled.div`
  width: 43%;
  ul {
      list-style-type: none;
  }
  a {
    padding-left: 30px;
  }
`;

const VerticalDivider = styled.div`
  height: 90%;
  width: 1px;
  background-color: #333333;
  margin-top: 10px;
`;

const ButtonsWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  padding-left: 20px;
  justify-content: flex-start;
  height: 40px;
  margin-bottom: 20px;
  ${props => props.slather && css`
    margin-bottom: 10px;
    ${props => props.started && 'margin-top: 20px;'}
  `}
  ${media.tablet`
    padding-left: 40px;
  `}
  a {
    height: 100%;
    font-size: 10px;
    width: 200px !important;
    ${props => props.slather && 'width: 135px !important;'}
    padding-top: 8px;
    padding-bottom: 10px;
    text-align: center;
    font-size: 13px;
    ${props => props.started && 'padding-top: 10px;'}
    ${media.tablet`
      width: 200px !important;
      ${props => props.slather && 'width: 150px !important;'}
      font-size: 15px;${props => props.started && 'padding-top: 7px;'}
    `}
  }
`;

const LogoContainer = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  padding-left: 25px;
  margin-bottom: 20px;
  ${media.tablet`
    padding-left: 45px;
  `}
  svg {
    width: 113px;
    height: 25px;
    ${props => props.slather && css`
      width: 142px;
      height: 29px;
    `}
    cursor: pointer;
    @media (min-width: 768px) {
      width: 140px !important;
    }
  }
`;

const Frame = styled.div`
  position: relative;
  padding: 4px 0px 0px 0px;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow-x: hidden;
  vertical-align: middle;
  overflow: hidden !important;
  padding-left: 20px;
  ${media.tablet`
    padding-left: 40px;
  `}
`;

const MyAccountSVG = styled.div`
  margin-right: 10px;
  margin-left: 8px;
  margin-top: 1px;
  height: 16px;
  width: 16px;
  svg {
    fill: currentColor;
    width: 100%;
    height: 100%;
  }
`;

export {
  TertiaryNavigation,
  Menu,
  SocialLinks,
  Divider,
  MenuToggle,
  MenuContent,
  MyAccountButton,
  LeftSide,
  RightSide,
  VerticalDivider,
  ButtonsWrapper,
  LogoContainer,
  MyAccountSVG,
  Frame,
};
