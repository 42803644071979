import React from 'react';

const Tiktok = () => (
    <svg width="34px" height="38px" viewBox="0 0 34 38" version="1.1" xmlns="http://www.w3.org/2000/svg">
        <title>TikTok</title>
        <g id="Page-2" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
            <g id="tiktok-white" fillRule="nonzero">
                <path d="M32.5612368,9.26 C27.9109993,9.26 24.1412368,5.49023759 24.1412368,0.84 C24.1412368,0.56 24.1412368,0.28 24.1412368,0 L18.3712368,0 L18.3712368,24.06 L18.3212368,25.54 C18.3157161,28.8910611 15.5956448,31.6036753 12.2445812,31.5999963 C8.8935175,31.5963174 6.17940879,28.8777373 6.181246,25.5266722 C6.1830832,22.175607 8.90017116,19.4600045 12.2512368,19.46 C12.826963,19.4613403 13.3994993,19.5455368 13.9512368,19.71 L13.9512368,13.71 C13.3428254,13.6125732 12.7273849,13.5657462 12.1112368,13.57 C7.00746737,13.5229709 2.43241937,16.7095065 0.707077561,21.5130333 C-1.01826425,26.3165602 0.483684437,31.6858453 4.45096441,34.8969766 C8.41824438,38.1081079 13.9826319,38.4583419 18.3212368,35.77 L18.3712368,35.77 C21.9374842,33.5893827 24.1120057,29.7100971 24.1112368,25.53 L24.1112368,12.35 C26.5596754,14.1523009 29.5209851,15.1230498 32.5612368,15.12 C32.9612368,15.12 33.3612368,15.12 33.7512368,15.07 L33.7512368,9.17 C33.357473,9.23054154 32.9596271,9.26063072 32.5612368,9.26 Z" id="Path"></path>
            </g>
        </g>       
    </svg>
);

export default Tiktok;