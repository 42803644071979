// This layout wrapper should only include things that appear on EVERY PAGE,
// and will be imported into other layouts
// This includes: Truly global styles, setup functionality, and
// a global error boundary.
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Amplify } from 'aws-amplify';

import ErrorBoundary from 'components/utils/ErrorBoundary';
import aws_config from 'src/aws_config';
import '../../style/normalize.scss';
import '../../style/base.scss';
import 'react-lazy-load-image-component/src/effects/blur.css';
import { ContextProvider } from 'components/Context';
Amplify.configure(aws_config);

class LayoutWrapper extends Component {
  render() {
    const { children } = this.props;
    return (
      <ErrorBoundary>
        <ContextProvider>
          {children}
        </ContextProvider>
      </ErrorBoundary>
    );
  }
}

LayoutWrapper.propTypes = {
  location: PropTypes.object,
  children: PropTypes.oneOfType([
    PropTypes.array,
    PropTypes.object,
  ]),
};

export default LayoutWrapper;
