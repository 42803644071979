import styled, { css } from 'styled-components';
import Section from 'styledComponents/Section';
import DefaultContainer from 'styledComponents/DefaultContainer';
import { variables, media } from 'styleUtils';


const Wrapper = styled(Section)`
  background: ${props => props.blog ? variables.yellow : variables.coral};
  height: 340px;
  width: 100%;
  display: flex;
  align-items: center;

  ${media.tablet`
    height: 250px;
  `}

  ${media.desktop`
    height: 160px;
  `}

  ${props => props.popUp || props.dismissed ? css`
    visibility: hidden;
    opacity: 0;
    position: fixed;
    transition: visibility .3s, opacity .3s;
  ` : ''}

  ${props => props.popUp && props.poppedUp ? css`
    bottom: 50px;
    z-index: 300;

    visibility: visible;
    opacity: 1;
    transition: visibility .3s, opacity .3s;
  ` : ''}
`;

const Container = styled(DefaultContainer)`
  ${media.desktop`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
  `}
`;

const CloseWrapper = styled.div`
  position: absolute;
  cursor: pointer;
  top: 20px;
  right: 20px;
  svg {
    transition: all .2s linear;

    height: 18px;
    width: 18px;
  }
  svg:hover {
    transition: all .2s linear;
    height: 20px;
    width: 20px;

  }

`;

export { Wrapper, Container, CloseWrapper };
