/* eslint-disable max-statements */
/* eslint-disable no-nested-ternary */
import React from 'react';
import styled, { css } from 'styled-components';
import { variables, media } from 'styleUtils';
import Link from 'components/utils/Link';

const borderWidth = '10px';

const NavLink = styled(Link)`
  display: block;
  text-decoration: none;
  cursor: pointer;
  border-bottom: none;
  border-left: 0 solid transparent;
  padding-left: 10px;
  @media (min-width: 768px) {
    border-left: ${borderWidth} solid transparent;
    padding-left: 35px;
  }
`;

// eslint-disable-next-line no-unused-vars
const LargeLink = styled(({ condition, ...rest }) => (
  <NavLink {...rest} />
))`
  transition: all 0.7s ease, border-color 0.3s ease;

  :hover {
    transition: all 0.7s ease;
    text-decoration: underline;
    color: #333333;
  }
  font-size: 16px;
  line-height: 30px;
  padding-left: 10px;
  margin-left: -10px;
  ${media.tablet`
    font-size: 19px;
    letter-spacing: -0.6px;
    ${props => props.slather && css`
      font-size: 30px;
      line-height: 32px;
      margin-left: 20px;
    `}
  `}
  ${props => props.slather && css`
    line-height: 30px;
    font-size: 17px;
    padding-left: 30px;
  `}
  ${props => props.solo && css`
    padding-left: 15px;
    color: #333333;
    margin-top: 0px;
    width: 235px;
    padding-bottom: 5px;
    margin-bottom: 0px;
    ${media.tablet`
      :hover {
        color: #333333;
        transition: all 0.7s ease;
        text-decoration: none;
        background-color: ${variables.newYellow};
      }
      padding-left: 0;
      margin-bottom: 5px;
      height: 27px;
      margin-left: -5px;
      line-height: 27px;
    `}
  `}
`;

const SmallLink = styled(NavLink)`
  color: ${variables.midGrey};
  font-size: 10px;
  line-height: 1.86;
  &:hover {
    color: ${variables.darkGrey};
  }
  @media (max-width: 767px) {
    padding-left: 0;
    border-left: 0;
  }
  font-size: 15px;
`;

export {
  LargeLink,
  SmallLink,
};
