import React from 'react';
import PropTypes from 'prop-types';
import CookieHandler from 'components/CookieHandler';
import ContextConsumer from 'components/Context';
import Promo99 from './Promo99';


const currentPromos = {
  'latisse-promo': {
    BannerComponent: Promo99,
    cookie: 'promo-latisse99',
    promoTitle: 'promo99',
    promoCode: 'LATISSE99',
  },
  'influenster': {
    BannerComponent: Promo99,
    cookie: 'promo-influenster',
    promoTitle: 'promo99',
    promoCode: 'INFLUENSTER',
  },
};

const PromoBanner = ({ context, pageTitle }) => {
  // Use the pageTitle to check if we have a promo currently active, add new promos to the "currentPromos" object
  if (currentPromos[pageTitle]) {
    const { BannerComponent, cookie, promoTitle, promoCode } = currentPromos[pageTitle];
    return (
      <CookieHandler
        action={promoTitle}
        cookie={cookie}
        menuOpen={context.data.menuOpen}
        render={({ display }) => display ? <BannerComponent promoCode={promoCode}/> : null}
      />
    );
  }
  return null;
};


PromoBanner.propTypes = {
  context: PropTypes.object,
  pageTitle: PropTypes.string.isRequired,
};

/* eslint-disable-next-line react/display-name */
export default props => (
  <ContextConsumer>
    {context => <PromoBanner {...props} context={context} />}
  </ContextConsumer>
);
