import styled from 'styled-components';
import { variables, media } from 'styleUtils';

const BannerWrapper = styled.div`
  position: sticky;
  top: 0;
  z-index: 100;
  display: ${props => props.menuOpen ? 'none' : ''};
`;

const Banner = styled.div`
  background-color: ${variables.darkGrey};
  width: 100%;
  text-align: center;
  padding: 25px;
  text-transform: uppercase;
  font-size: 14px;
  font-family: 'GT-pressura';
  letter-spacing: 1px;
  line-height: 22px;

  p {
    color: ${variables.white};

    ${media.tablet`
      margin-right: 1rem;
    `}

    span {
      border-bottom: solid 1px ${variables.darkGrey};
    }
  }

  ${media.tablet`
    display: flex;
    justify-content: center;
    padding: 20px;
  `}
`;

export {
  BannerWrapper,
  Banner,
};
