import React from 'react';
import { StaticImage } from 'gatsby-plugin-image';

const Accreditation = () => (
  <div className="accred-section">
    <div className="legitscript">
      <a
        href="https://legitscript.com/pharmacy/apostrophe.com"
        target="_blank"
        rel="nofollow noopener noreferrer"
        alt="LegitScript Approved"
      >
        <StaticImage src="https://static.legitscript.com/seals/3879723.png" alt="Apostrophe LegitScript seal"
        formats={['auto', 'webp']}
        />
      </a>
    </div>
    <div className="bbb">
      <a
        href="https://www.bbb.org/us/ca/oakland/profile/dermatologist/apostrophe-1116-880490#sealclick"
        target="_blank"
        rel="nofollow noopener noreferrer"
      >
        <StaticImage src="https://seal-goldengate.bbb.org/seals/blue-seal-293-61-whitetxt-bbb-880490.png" 
        alt="Apostrophe BBB Business Review" 
        formats={['auto', 'webp']}
        />
      </a>
    </div>
  </div>
);

export default Accreditation;
