/* eslint-disable react/prop-types */
/* eslint-disable no-tabs */
import React from 'react';

function HimsHers({ fill = 'white' }) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 366.23 32.87">
      <path
        fill={fill}
        d="M6.54 32.87C3 32.87 0 30.86 0 27.03s3.04-6.08 7.23-6.08c2.1 0 4.4.66 6.54 1.27v-2.63c0-3.78-2.55-5.84-5.96-5.84-2.63 0-4.44 1.36-5.51 3.08l-1.48-1.4c1.6-2.38 4.15-3.66 7.03-3.66 4.69 0 7.89 2.71 7.89 7.73v12.91h-1.89v-3.29c-1.6 1.93-3.74 3.74-7.32 3.74zm-4.57-5.79c0 2.55 2.18 3.82 4.85 3.82 3 0 5.55-2.22 6.95-4.28v-2.51c-1.97-.62-4.23-1.19-6.25-1.19-3.74 0-5.55 1.81-5.55 4.15z"
      ></path>
      <g fill={fill}>
        <path d="M151.33 29.57c-1.59 1.99-3.86 3.31-6.43 3.31-3.77 0-6.61-2.17-6.61-6.12 0-3.12 2.91-5.17 5.36-6.49-1.19-1.56-2.69-3.15-2.69-5.27 0-2.66 2.17-4.44 4.84-4.44s4.84 1.78 4.84 4.44-2.11 4.29-4.29 5.66l4.78 5.76c.52-1.07.64-2.02.64-3.67h1.93c0 1.22-.28 3.37-1.29 5.24l3.81 4.6h-2.42l-2.47-3.01zm-6.64-8.05c-2.08 1.13-4.47 2.79-4.47 5.42s2.27 4.32 4.75 4.32c2.17 0 3.95-1.38 5.17-3.12l-5.45-6.61zM142.88 15c0 1.53 1.5 3.25 2.45 4.41 1.53-.95 3.37-2.3 3.37-4.41 0-1.81-1.32-2.82-2.91-2.82s-2.91 1.01-2.91 2.82zM71.98 5.53c0 2.07-1.68 3.74-3.74 3.74s-3.75-1.68-3.75-3.74 1.68-3.75 3.75-3.75 3.74 1.68 3.74 3.75M114.22 28.57c0-1.39.86-2.33 2.54-2.33 1.97 0 2.54.7 3.24 3.52.25.98.82 1.56 2.38 1.56 2.09 0 3.24-1.15 3.24-2.83 0-1.47-1.5-2.66-4.41-4.5-3.19-1.97-4.96-3.85-4.96-6.59 0-4.26 4.73-6.84 9.07-6.84 4.87 0 6.76 2.09 6.76 4.05 0 1.31-.86 2.01-2.33 2.01-1.84 0-2.42-.74-3.03-2.95-.29-1.11-.86-1.56-1.97-1.56-1.76 0-2.95.9-2.95 2.54s1.54 2.54 4.94 4.67c3.19 1.88 4.63 3.93 4.63 6.35 0 3.97-4.28 7.21-9.65 7.21s-7.49-2.17-7.49-4.3"></path>
        <path d="M45.71 16.95c0-1.51 1.22-3.18 3.61-3.18s3.57 1.44 3.57 3.18v10.78c0 1.59-.07 2.11-.29 2.72-.18.48-.61.61-1.27.79-.39.09-.61.22-.61.66 0 .31.13.66.48.7h35.13c.61 0 1.18-.04 1.18-.74 0-.53-.22-.57-.66-.7-.92-.22-1.88-.48-1.97-1.66-.09-.88-.09-10.84-.09-12.59 0-1.54 1.36-3.13 3.5-3.13s3.27 1.35 3.27 3.18c0 1.71-.03 8.84-.03 11.18 0 1.98 0 2.58-1.58 3.07-.44.13-.61.22-.61.66 0 .7.48.74 1.1.74h8.8c1.18 0 1.58-.09 1.58-.83 0-.48-.31-.57-.79-.7-1.4-.35-1.62-.66-1.62-1.93 0 0-.02-10.47-.02-12.22s1.51-3.13 3.46-3.13c0 0 3.22-.22 3.22 3.32 0 .75.09 11.46.09 11.46 0 2.15-.57 2.28-1.45 2.54-.48.13-.74.22-.74.66 0 .74.53.83.96.83h9.72c.61-.04.7-.31.7-.7 0-.61-.31-.7-.83-.83-1.4-.35-1.44-1.05-1.44-1.8v-8.72c0-5.61-.27-6.35-.84-7.36-1.53-2.72-5.39-2.76-5.78-2.76-2.98 0-5.26 1.4-6.61 2.67-.79.74-.88.74-.96.74-.18 0-.22-.04-.88-.92-1.27-1.75-3.02-2.45-5.21-2.45-2.41 0-4.29.92-6.44 2.85-.31.31-.39.31-.53.31-.22 0-.22-.18-.22-.61v-1.88c0-.26 0-.61-.31-.61-.13 0-.22 0-1.01.48-2.5 1.45-5.34 2.19-7.18 2.58-.79.13-1.01.18-1.01.7 0 .35.04.61.57.7 2.1.35 2.27.39 2.31 1.97v12.05c0 .29.02 2.32-2.94 2.32s-3.07-1.8-3.07-2.36l-.02-17.52c0-.53-.04-.88-.39-.88-.09 0-.18 0-.61.31-2.1 1.23-4.25 2.1-6.79 2.67-1.31.26-1.36.31-1.36.74 0 .35.09.66.53.74 1.23.31 1.73.42 1.73 1.14v13.18c0 .25 0 1.97-2.52 1.97s-2.68-1.49-2.68-1.97V17.67c0-2.01-.18-3.85-1.36-5.08-1.36-1.45-3.15-2.06-5.12-2.06-2.63 0-4.91 1.01-6.92 2.72-.57.53-.66.53-.7.53-.22 0-.22-.22-.22-.7l.04-7.49V.97c0-.53 0-.92-.35-.92-.09 0-.18 0-.61.31-2.1 1.23-4.25 2.1-6.79 2.67-1.31.26-1.36.31-1.36.74 0 .35.09.66.53.74 1.23.31 1.45.35 1.58 1.14.09.57.09 3.45.09 4.37V28c0 2.15 0 2.72-1.45 3.24-.48.13-.74.22-.74.66 0 .7.35.7 1.14.7h9.02c.79 0 1.36-.04 1.36-.83 0-.44-.44-.53-.83-.61-1.49-.39-1.62-.83-1.62-1.93l.16-12.28z"></path>
        <g>
          <path d="M206.84 25.52c-.47-.22-.6-.04-.91.29-1.95 3.07-12.13 7.19-13.33-3.03-.02-.17.02-.2.44-.3l11.21-2.91c1.88-.47 2.49-1.06 2.49-2.43 0-3.65-4.2-6.74-9.16-6.74-6.79 0-11.54 4.74-11.54 11.54s4.4 10.78 10.96 10.78c4.22 0 8.07-2.27 9.74-5.25l.27-.58c.26-.55.46-1.08-.17-1.38zm-12.41-10.83c.86-.98 2.04-1.57 3.15-1.57 1.9 0 3.44 1.49 3.44 3.31 0 .85-.38 1.22-1.69 1.66l-6.66 2.11s-.05 0-.06-.01c0 0-.03-.03-.02-.05.13-1.8.45-3.86 1.84-5.44zM224.63 10.5c-.49-.11-1.02-.12-1.56-.05-.2.03-.4.06-.61.11-.08.02-.15.04-.22.05h-.02c-1.54.38-2.93 1.23-4.44 2.6-.31.31-.39.31-.53.31-.22 0-.22-.18-.22-.61v-1.89c0-.26 0-.61-.31-.61-.13 0-.22 0-1.01.48-2.5 1.45-5.35 2.19-7.19 2.59-.79.13-1.01.18-1.01.7 0 .35.04.61.57.7 2.1.35 2.27.39 2.32 1.97v11.84c-.02 1.46-.22 1.98-1.6 2.41-.44.13-.61.22-.61.66 0 .7.48.75 1.1.75h9.46c.61 0 1.18-.04 1.18-.75 0-.53-.22-.57-.66-.7-.92-.22-1.89-.48-1.97-1.67-.09-.88-.09-6.53-.09-7.85v-4.76c0-2.03 2.02-3.13 3.51-3.13.79 0 1.63.43 2.26 1.14.24.31.41.56.41.56.42.7.85 1.04 1.52 1.2.97.23 2.03-.12 2.58-.95 1.27-1.92-.42-4.58-2.83-5.1zM237.16 11.97c1.11 0 1.68.45 1.97 1.56.61 2.21 1.19 2.95 3.03 2.95 1.48 0 2.34-.7 2.34-2.01 0-1.97-1.89-4.06-6.76-4.06-4.35 0-9.08 2.58-9.08 6.85 0 2.75 1.76 4.63 4.96 6.6 2.91 1.84 4.41 3.03 4.41 4.51 0 1.68-1.15 2.83-3.24 2.83-1.56 0-2.13-.57-2.38-1.56-.7-2.83-1.27-3.53-3.24-3.53-1.68 0-2.54.94-2.54 2.34 0 2.13 2.13 4.3 7.5 4.3s9.66-3.24 9.66-7.22c0-2.42-1.43-4.47-4.63-6.35-3.4-2.13-4.94-3.12-4.94-4.67 0-1.64 1.19-2.54 2.95-2.54z"></path>
          <path
            fillRule="evenodd"
            d="M185.6 31.03c-1.4-.37-1.62-.78-1.65-1.71v-11.7c0-2.01-.18-3.85-1.36-5.08-1.36-1.45-3.15-2.06-5.12-2.06-2.63 0-4.91 1.01-6.92 2.72-.57.53-.66.53-.7.53-.22 0-.22-.22-.22-.7l.04-7.49V.92c0-.53 0-.92-.35-.92-.09 0-.18 0-.61.31-2.1 1.23-4.25 2.1-6.79 2.67-1.31.26-1.36.31-1.36.74 0 .35.09.66.53.74 1.23.31 1.45.35 1.58 1.14.09.57.09 3.45.09 4.37v17.97c0 2.15 0 2.72-1.45 3.24-.48.13-.74.22-.74.66 0 .7.35.7 1.14.7h9.02c.79 0 1.36-.04 1.36-.83 0-.44-.44-.53-.83-.61-1.49-.39-1.62-.83-1.62-1.93l.16-12.28c0-1.51 1.22-3.18 3.61-3.18s3.57 1.44 3.57 3.18v10.78c0 1.59-.07 2.11-.29 2.72-.18.48-.61.61-1.27.79-.39.09-.61.22-.61.66 0 .31.13.66.48.7h9.77c.8 0 1.39-.09 1.39-.89 0-.45-.45-.54-.85-.62z"
          ></path>
        </g>
      </g>
      <g fill={fill}>
        <path d="M284.35 22.35c0 5.84-3.7 10.52-9.25 10.52-3.41 0-6.33-1.64-7.89-4.48v4.03h-1.97V3.65h1.97v12.58c1.52-2.75 4.48-4.44 7.89-4.44 5.55 0 9.25 4.77 9.25 10.56zm-2.01 0c0-4.6-2.96-8.59-7.48-8.59s-7.65 3.95-7.65 8.59 3.21 8.51 7.65 8.51c4.69 0 7.48-3.7 7.48-8.51zM299.76 13.8h-.74c-4.4 0-7.11 3.78-7.11 8.8v9.82h-1.97V12.28h1.85v3.95c1.32-2.75 4.15-4.44 7.36-4.44h.62v2.01zM309.38 32.87c-3.54 0-6.54-2.01-6.54-5.84s3.04-6.08 7.23-6.08c2.1 0 4.4.66 6.54 1.27v-2.63c0-3.78-2.55-5.84-5.96-5.84-2.63 0-4.44 1.36-5.51 3.08l-1.48-1.4c1.6-2.38 4.15-3.66 7.03-3.66 4.69 0 7.89 2.71 7.89 7.73v12.91h-1.89v-3.29c-1.6 1.93-3.74 3.74-7.32 3.74zm-4.56-5.8c0 2.55 2.18 3.82 4.85 3.82 3 0 5.55-2.22 6.95-4.28V24.1c-1.97-.62-4.23-1.19-6.25-1.19-3.74 0-5.55 1.81-5.55 4.15zM339.8 32.42V22.6c0-5.22-1.27-8.84-6.21-8.84-4.56 0-7.03 3.82-7.03 8.84v9.82h-1.97V12.28h1.93v3.49c1.52-2.75 3.91-3.99 7.28-3.99 6.21 0 7.98 4.69 7.98 10.56v10.07h-1.97zM347.11 22.35c0-5.8 3.7-10.56 9.25-10.56 3.41 0 6.37 1.69 7.89 4.44V3.65h1.97v28.78h-1.97V28.4c-1.56 2.84-4.48 4.48-7.89 4.48-5.55 0-9.25-4.69-9.25-10.52zm2.01 0c0 4.81 2.8 8.51 7.48 8.51 4.44 0 7.65-3.82 7.65-8.51s-3.21-8.59-7.65-8.59-7.48 3.99-7.48 8.59z"></path>
      </g>
    </svg>
  );
}

export default HimsHers;
