import styled from 'styled-components';
import { media } from 'styleUtils';

const Gradient = styled.div`
  background: linear-gradient(to right, rgba(245,244,162,1) 0%, rgba(242,197,177,1) 100%);
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;

  h2 {
    max-width: 310px;
    text-align: center;
    margin-bottom: 0;

    ${media.tablet`
      max-width: 100%;
    `}
  }
`;

export { Gradient };
