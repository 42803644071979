import React, { Component } from 'react';
import PropTypes from 'prop-types';
import LayoutWrapper from '../Wrapper';
import Navigation from 'components/Navigation';
import ThePossessive from 'components/NewsletterSignup/ThePossessive';
import Footer from 'components/Footer';

class BlogLayout extends Component {
  render() {
    const { location, children } = this.props;

    return (
      <LayoutWrapper>
        <div style={{ position: 'relative' }}>
          <Navigation location={location}/>
          {children}
          <ThePossessive
            blog
            popUp
            dismissable
            location={location}
          />
          <Footer location={location}/>
        </div>
      </LayoutWrapper>
    );
  }
}

BlogLayout.propTypes = {
  location: PropTypes.object,
  children: PropTypes.oneOfType([
    PropTypes.object,
    PropTypes.array,
  ]),
};

export default BlogLayout;
